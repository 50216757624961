/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum StatusReportState {
  canceled = "canceled",
  in_review = "in_review",
  rejected = "rejected",
  released = "released",
  unreleased = "unreleased",
}

/**
 * select columns of table "cockpit_be.change_request"
 */
export enum cockpit_be_change_request_select_column {
  assignee = "assignee",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  reportable = "reportable",
  reporter = "reporter",
  status = "status",
  summary = "summary",
  updated_at = "updated_at",
  url = "url",
}

/**
 * select "cockpit_be_change_request_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.change_request"
 */
export enum cockpit_be_change_request_select_column_cockpit_be_change_request_aggregate_bool_exp_bool_and_arguments_columns {
  reportable = "reportable",
}

/**
 * select "cockpit_be_change_request_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.change_request"
 */
export enum cockpit_be_change_request_select_column_cockpit_be_change_request_aggregate_bool_exp_bool_or_arguments_columns {
  reportable = "reportable",
}

/**
 * select columns of table "cockpit_be.initiative"
 */
export enum cockpit_be_initiative_select_column {
  actual_end_date = "actual_end_date",
  actual_start_date = "actual_start_date",
  category = "category",
  cross_section_selections = "cross_section_selections",
  customer_impact = "customer_impact",
  deleted = "deleted",
  deputy_manager = "deputy_manager",
  deputy_sponsor = "deputy_sponsor",
  description = "description",
  execution_type = "execution_type",
  initiative_type = "initiative_type",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  kind = "kind",
  manager = "manager",
  organisation_unit = "organisation_unit",
  overview_url = "overview_url",
  parent_issue_id = "parent_issue_id",
  phase = "phase",
  planned_end_date = "planned_end_date",
  planned_start_date = "planned_start_date",
  portfolio_project_id = "portfolio_project_id",
  priority = "priority",
  public = "public",
  roadmap_relevance = "roadmap_relevance",
  score_risk = "score_risk",
  score_size = "score_size",
  score_value = "score_value",
  size = "size",
  snow_sync_enabled = "snow_sync_enabled",
  sponsor = "sponsor",
  stakeholders = "stakeholders",
  status = "status",
  strategy = "strategy",
  summary = "summary",
  theme_tags = "theme_tags",
  type = "type",
  updated_at = "updated_at",
}

/**
 * select "cockpit_be_initiative_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.initiative"
 */
export enum cockpit_be_initiative_select_column_cockpit_be_initiative_aggregate_bool_exp_bool_and_arguments_columns {
  deleted = "deleted",
  public = "public",
  snow_sync_enabled = "snow_sync_enabled",
}

/**
 * select "cockpit_be_initiative_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.initiative"
 */
export enum cockpit_be_initiative_select_column_cockpit_be_initiative_aggregate_bool_exp_bool_or_arguments_columns {
  deleted = "deleted",
  public = "public",
  snow_sync_enabled = "snow_sync_enabled",
}

/**
 * select columns of table "cockpit_be.milestone"
 */
export enum cockpit_be_milestone_select_column {
  actual_end_date = "actual_end_date",
  description = "description",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  planned_end_date = "planned_end_date",
  reportable = "reportable",
  status = "status",
  summary = "summary",
  updated_at = "updated_at",
  url = "url",
}

/**
 * select "cockpit_be_milestone_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.milestone"
 */
export enum cockpit_be_milestone_select_column_cockpit_be_milestone_aggregate_bool_exp_bool_and_arguments_columns {
  reportable = "reportable",
}

/**
 * select "cockpit_be_milestone_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.milestone"
 */
export enum cockpit_be_milestone_select_column_cockpit_be_milestone_aggregate_bool_exp_bool_or_arguments_columns {
  reportable = "reportable",
}

/**
 * select columns of table "cockpit_be.permission"
 */
export enum cockpit_be_permission_select_column {
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
  statusbericht_teilberechtigte = "statusbericht_teilberechtigte",
  statusbericht_vollberechtigte = "statusbericht_vollberechtigte",
  user_id = "user_id",
}

/**
 * select "cockpit_be_permission_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.permission"
 */
export enum cockpit_be_permission_select_column_cockpit_be_permission_aggregate_bool_exp_bool_and_arguments_columns {
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
  statusbericht_teilberechtigte = "statusbericht_teilberechtigte",
  statusbericht_vollberechtigte = "statusbericht_vollberechtigte",
}

/**
 * select "cockpit_be_permission_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.permission"
 */
export enum cockpit_be_permission_select_column_cockpit_be_permission_aggregate_bool_exp_bool_or_arguments_columns {
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
  statusbericht_teilberechtigte = "statusbericht_teilberechtigte",
  statusbericht_vollberechtigte = "statusbericht_vollberechtigte",
}

/**
 * select columns of table "cockpit_be.problem"
 */
export enum cockpit_be_problem_select_column {
  assignee = "assignee",
  description = "description",
  end_date = "end_date",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  reportable = "reportable",
  status = "status",
  summary = "summary",
  updated_at = "updated_at",
  url = "url",
}

/**
 * select "cockpit_be_problem_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.problem"
 */
export enum cockpit_be_problem_select_column_cockpit_be_problem_aggregate_bool_exp_bool_and_arguments_columns {
  reportable = "reportable",
}

/**
 * select "cockpit_be_problem_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.problem"
 */
export enum cockpit_be_problem_select_column_cockpit_be_problem_aggregate_bool_exp_bool_or_arguments_columns {
  reportable = "reportable",
}

/**
 * select columns of table "cockpit_be.psp_activity"
 */
export enum cockpit_be_psp_activity_select_column {
  description = "description",
  end_date = "end_date",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  start_date = "start_date",
  status = "status",
  summary = "summary",
  top_level = "top_level",
  updated_at = "updated_at",
  url = "url",
}

/**
 * select "cockpit_be_psp_activity_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.psp_activity"
 */
export enum cockpit_be_psp_activity_select_column_cockpit_be_psp_activity_aggregate_bool_exp_bool_and_arguments_columns {
  top_level = "top_level",
}

/**
 * select "cockpit_be_psp_activity_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.psp_activity"
 */
export enum cockpit_be_psp_activity_select_column_cockpit_be_psp_activity_aggregate_bool_exp_bool_or_arguments_columns {
  top_level = "top_level",
}

/**
 * select columns of table "cockpit_be.risk_countermeasure"
 */
export enum cockpit_be_risk_countermeasure_select_column {
  assignee = "assignee",
  end_date = "end_date",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  reportable = "reportable",
  risk_issue_id = "risk_issue_id",
  status = "status",
  summary = "summary",
  updated_at = "updated_at",
  url = "url",
}

/**
 * select "cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.risk_countermeasure"
 */
export enum cockpit_be_risk_countermeasure_select_column_cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_and_arguments_columns {
  reportable = "reportable",
}

/**
 * select "cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.risk_countermeasure"
 */
export enum cockpit_be_risk_countermeasure_select_column_cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_or_arguments_columns {
  reportable = "reportable",
}

/**
 * select columns of table "cockpit_be.risk"
 */
export enum cockpit_be_risk_select_column {
  assignee = "assignee",
  damage_impact = "damage_impact",
  description = "description",
  estimated_costs = "estimated_costs",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  mitigation = "mitigation",
  next_assessment = "next_assessment",
  priority = "priority",
  probability = "probability",
  reportable = "reportable",
  status = "status",
  summary = "summary",
  updated_at = "updated_at",
  url = "url",
}

/**
 * select "cockpit_be_risk_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cockpit_be.risk"
 */
export enum cockpit_be_risk_select_column_cockpit_be_risk_aggregate_bool_exp_bool_and_arguments_columns {
  reportable = "reportable",
}

/**
 * select "cockpit_be_risk_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cockpit_be.risk"
 */
export enum cockpit_be_risk_select_column_cockpit_be_risk_aggregate_bool_exp_bool_or_arguments_columns {
  reportable = "reportable",
}

/**
 * select columns of table "cockpit_be.role"
 */
export enum cockpit_be_role_select_column {
  group_name = "group_name",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  role_name = "role_name",
  user_id = "user_id",
}

/**
 * select columns of table "cost_control.budget_position"
 */
export enum cost_control_budget_position_select_column {
  amount = "amount",
  controlling_period_id = "controlling_period_id",
  expense_type = "expense_type",
  id = "id",
}

/**
 * select columns of table "cost_control.category_option"
 */
export enum cost_control_category_option_select_column {
  category_id = "category_id",
  id = "id",
  name = "name",
}

/**
 * select columns of table "cost_control.controlling_period"
 */
export enum cost_control_controlling_period_select_column {
  controlling_plan_id = "controlling_plan_id",
  id = "id",
  period = "period",
}

/**
 * select columns of table "cost_control.controlling_plan_overview"
 */
export enum cost_control_controlling_plan_overview_select_column {
  actual = "actual",
  budget = "budget",
  controlling_plan_id = "controlling_plan_id",
  expense_type = "expense_type",
  forecast = "forecast",
  initiative_issue_id = "initiative_issue_id",
  name = "name",
  period = "period",
  planned = "planned",
  type = "type",
}

/**
 * select columns of table "cost_control.controlling_plan"
 */
export enum cost_control_controlling_plan_select_column {
  id = "id",
  initiative_issue_id = "initiative_issue_id",
  type = "type",
  url = "url",
}

/**
 * select columns of table "cost_control.cost_plan_position"
 */
export enum cost_control_cost_plan_position_select_column {
  amount = "amount",
  controlling_period_id = "controlling_period_id",
  external_id = "external_id",
  forecast = "forecast",
  forecasted_hours = "forecasted_hours",
  id = "id",
  planned_hours = "planned_hours",
  service_id = "service_id",
}

/**
 * select columns of table "cost_control.expense_position"
 */
export enum cost_control_expense_position_select_column {
  created_at = "created_at",
  description = "description",
  expense_id = "expense_id",
  hour_amount = "hour_amount",
  hourly_rate = "hourly_rate",
  id = "id",
  monetary_amount = "monetary_amount",
  profile_name = "profile_name",
}

/**
 * select columns of table "cost_control.expense"
 */
export enum cost_control_expense_select_column {
  cost_plan_position_id = "cost_plan_position_id",
  description = "description",
  document_date = "document_date",
  id = "id",
  invoice_date = "invoice_date",
}

export enum cost_control_expense_type_enum {
  CAPEX = "CAPEX",
  OPEX = "OPEX",
}

/**
 * select columns of table "cost_control.permission"
 */
export enum cost_control_permission_select_column {
  controlling_plan_id = "controlling_plan_id",
  issue_id = "issue_id",
  jira_project_id = "jira_project_id",
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
  user_id = "user_id",
}

/**
 * select "cost_control_permission_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cost_control.permission"
 */
export enum cost_control_permission_select_column_cost_control_permission_aggregate_bool_exp_bool_and_arguments_columns {
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
}

/**
 * select "cost_control_permission_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cost_control.permission"
 */
export enum cost_control_permission_select_column_cost_control_permission_aggregate_bool_exp_bool_or_arguments_columns {
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
}

/**
 * select columns of table "cost_control.profile"
 */
export enum cost_control_profile_select_column {
  external_id = "external_id",
  hourly_rate = "hourly_rate",
  id = "id",
  name = "name",
  supplier_id = "supplier_id",
}

/**
 * select columns of table "cost_control.service_category"
 */
export enum cost_control_service_category_select_column {
  category_option_id = "category_option_id",
  service_id = "service_id",
}

/**
 * select columns of table "cost_control.service"
 */
export enum cost_control_service_select_column {
  controlling_plan_id = "controlling_plan_id",
  description = "description",
  expense_type = "expense_type",
  id = "id",
  supplier_id = "supplier_id",
}

/**
 * select columns of table "cost_control.supplier"
 */
export enum cost_control_supplier_select_column {
  controlling_plan_id = "controlling_plan_id",
  external_id = "external_id",
  group = "group",
  id = "id",
  name = "name",
}

/**
 * select columns of table "status_report.change_request"
 */
export enum status_report_change_request_select_column {
  assignee = "assignee",
  description = "description",
  external_id = "external_id",
  id = "id",
  reporter = "reporter",
  status = "status",
  status_report_id = "status_report_id",
  url = "url",
}

/**
 * select columns of table "status_report.milestone"
 */
export enum status_report_milestone_select_column {
  actual_date = "actual_date",
  description = "description",
  external_id = "external_id",
  id = "id",
  planned_date = "planned_date",
  status = "status",
  status_report_id = "status_report_id",
  url = "url",
}

/**
 * select columns of table "status_report.permission"
 */
export enum status_report_permission_select_column {
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  report_id = "report_id",
  sponsor = "sponsor",
  statusbericht_teilberechtigte = "statusbericht_teilberechtigte",
  statusbericht_vollberechtigte = "statusbericht_vollberechtigte",
  user_id = "user_id",
}

/**
 * select "status_report_permission_aggregate_bool_exp_bool_and_arguments_columns" columns of table "status_report.permission"
 */
export enum status_report_permission_select_column_status_report_permission_aggregate_bool_exp_bool_and_arguments_columns {
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
  statusbericht_teilberechtigte = "statusbericht_teilberechtigte",
  statusbericht_vollberechtigte = "statusbericht_vollberechtigte",
}

/**
 * select "status_report_permission_aggregate_bool_exp_bool_or_arguments_columns" columns of table "status_report.permission"
 */
export enum status_report_permission_select_column_status_report_permission_aggregate_bool_exp_bool_or_arguments_columns {
  manager = "manager",
  portfolio_viewer = "portfolio_viewer",
  program_manager = "program_manager",
  sponsor = "sponsor",
  statusbericht_teilberechtigte = "statusbericht_teilberechtigte",
  statusbericht_vollberechtigte = "statusbericht_vollberechtigte",
}

/**
 * select columns of table "status_report.problem"
 */
export enum status_report_problem_select_column {
  assignee = "assignee",
  due_date = "due_date",
  external_id = "external_id",
  id = "id",
  name = "name",
  status = "status",
  status_report_id = "status_report_id",
  url = "url",
}

/**
 * select columns of table "status_report.project_costs"
 */
export enum status_report_project_costs_select_column {
  amount = "amount",
  date = "date",
  id = "id",
  status_report_id = "status_report_id",
  type = "type",
}

/**
 * select columns of table "status_report.project_state"
 */
export enum status_report_project_state_select_column {
  comment = "comment",
  id = "id",
  state = "state",
  status_report_id = "status_report_id",
  type = "type",
}

/**
 * select columns of table "status_report.risk_countermeasure"
 */
export enum status_report_risk_countermeasure_select_column {
  assignee = "assignee",
  description = "description",
  end_date = "end_date",
  external_id = "external_id",
  id = "id",
  risk_id = "risk_id",
  status = "status",
}

/**
 * select columns of table "status_report.risk"
 */
export enum status_report_risk_select_column {
  assignee = "assignee",
  damage_extent = "damage_extent",
  description = "description",
  estimated_cost = "estimated_cost",
  external_id = "external_id",
  id = "id",
  next_assessment = "next_assessment",
  probability_of_occurrence = "probability_of_occurrence",
  status = "status",
  status_report_id = "status_report_id",
  url = "url",
}

/**
 * select columns of table "status_report.status_report_attachment"
 */
export enum status_report_status_report_attachment_select_column {
  filename = "filename",
  statusreport_id = "statusreport_id",
}

/**
 * select columns of table "status_report.status_report_history"
 */
export enum status_report_status_report_history_select_column {
  id = "id",
  new_state = "new_state",
  status_report_id = "status_report_id",
  timestamp = "timestamp",
  user = "user",
}

/**
 * select columns of table "status_report.status_report"
 */
export enum status_report_status_report_select_column {
  actual_costs = "actual_costs",
  actual_end_date = "actual_end_date",
  actual_start_date = "actual_start_date",
  change_requests_comment = "change_requests_comment",
  description = "description",
  external_program_id = "external_program_id",
  external_project_id = "external_project_id",
  id = "id",
  initiative_issue_id = "initiative_issue_id",
  milestones_comment = "milestones_comment",
  next_milestones = "next_milestones",
  past_successes = "past_successes",
  planned_costs = "planned_costs",
  planned_end_date = "planned_end_date",
  planned_start_date = "planned_start_date",
  portfolio = "portfolio",
  problems_comment = "problems_comment",
  project_manager = "project_manager",
  project_name = "project_name",
  project_phase = "project_phase",
  project_status = "project_status",
  report_date = "report_date",
  report_title = "report_title",
  risks_comment = "risks_comment",
  state = "state",
}

/**
 * Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'.
 */
export interface Boolean_comparison_exp {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: boolean[] | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: boolean[] | null;
}

/**
 * Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'.
 */
export interface Int_comparison_exp {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: number[] | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: number[] | null;
}

/**
 * Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'.
 */
export interface String_comparison_exp {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: string[] | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: string[] | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
}

export interface cockpit_be_change_request_aggregate_bool_exp {
  bool_and?: cockpit_be_change_request_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_change_request_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_change_request_aggregate_bool_exp_count | null;
}

export interface cockpit_be_change_request_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_change_request_select_column_cockpit_be_change_request_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_change_request_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_change_request_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_change_request_select_column_cockpit_be_change_request_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_change_request_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_change_request_aggregate_bool_exp_count {
  arguments?: cockpit_be_change_request_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_change_request_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.change_request". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_change_request_bool_exp {
  _and?: cockpit_be_change_request_bool_exp[] | null;
  _not?: cockpit_be_change_request_bool_exp | null;
  _or?: cockpit_be_change_request_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  initiative?: cockpit_be_initiative_bool_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  permissions?: cockpit_be_permission_bool_exp | null;
  permissions_aggregate?: cockpit_be_permission_aggregate_bool_exp | null;
  reportable?: Boolean_comparison_exp | null;
  reporter?: String_comparison_exp | null;
  reporter_user?: cockpit_be_user_bool_exp | null;
  status?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface cockpit_be_initiative_aggregate_bool_exp {
  bool_and?: cockpit_be_initiative_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_initiative_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_initiative_aggregate_bool_exp_count | null;
}

export interface cockpit_be_initiative_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_initiative_select_column_cockpit_be_initiative_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_initiative_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_initiative_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_initiative_select_column_cockpit_be_initiative_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_initiative_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_initiative_aggregate_bool_exp_count {
  arguments?: cockpit_be_initiative_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_initiative_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.initiative". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_initiative_bool_exp {
  _and?: cockpit_be_initiative_bool_exp[] | null;
  _not?: cockpit_be_initiative_bool_exp | null;
  _or?: cockpit_be_initiative_bool_exp[] | null;
  actual_end_date?: date_comparison_exp | null;
  actual_start_date?: date_comparison_exp | null;
  category?: String_comparison_exp | null;
  change_requests?: cockpit_be_change_request_bool_exp | null;
  change_requests_aggregate?: cockpit_be_change_request_aggregate_bool_exp | null;
  children?: cockpit_be_initiative_bool_exp | null;
  children_aggregate?: cockpit_be_initiative_aggregate_bool_exp | null;
  controlling_plans?: cost_control_controlling_plan_bool_exp | null;
  controlling_plans_aggregate?: cost_control_controlling_plan_aggregate_bool_exp | null;
  cross_section_selections?: jsonb_comparison_exp | null;
  customer_impact?: String_comparison_exp | null;
  deleted?: Boolean_comparison_exp | null;
  deputy_manager?: String_comparison_exp | null;
  deputy_manager_user?: cockpit_be_user_bool_exp | null;
  deputy_sponsor?: String_comparison_exp | null;
  deputy_sponsor_user?: cockpit_be_user_bool_exp | null;
  description?: String_comparison_exp | null;
  execution_type?: String_comparison_exp | null;
  initiative_type?: String_comparison_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  kind?: String_comparison_exp | null;
  manager?: String_comparison_exp | null;
  manager_user?: cockpit_be_user_bool_exp | null;
  milestones?: cockpit_be_milestone_bool_exp | null;
  milestones_aggregate?: cockpit_be_milestone_aggregate_bool_exp | null;
  organisation_unit?: String_comparison_exp | null;
  overview_url?: String_comparison_exp | null;
  parent?: cockpit_be_initiative_bool_exp | null;
  parent_issue_id?: String_comparison_exp | null;
  permissions?: cockpit_be_permission_bool_exp | null;
  permissions_aggregate?: cockpit_be_permission_aggregate_bool_exp | null;
  phase?: String_comparison_exp | null;
  planned_end_date?: date_comparison_exp | null;
  planned_start_date?: date_comparison_exp | null;
  portfolio?: cockpit_be_portfolio_bool_exp | null;
  portfolio_project_id?: String_comparison_exp | null;
  priority?: String_comparison_exp | null;
  problems?: cockpit_be_problem_bool_exp | null;
  problems_aggregate?: cockpit_be_problem_aggregate_bool_exp | null;
  psp_activities?: cockpit_be_psp_activity_bool_exp | null;
  psp_activities_aggregate?: cockpit_be_psp_activity_aggregate_bool_exp | null;
  public?: Boolean_comparison_exp | null;
  risks?: cockpit_be_risk_bool_exp | null;
  risks_aggregate?: cockpit_be_risk_aggregate_bool_exp | null;
  roadmap_relevance?: String_comparison_exp | null;
  score_risk?: Int_comparison_exp | null;
  score_size?: Int_comparison_exp | null;
  score_value?: Int_comparison_exp | null;
  size?: String_comparison_exp | null;
  snow_sync_enabled?: Boolean_comparison_exp | null;
  sponsor?: String_comparison_exp | null;
  sponsor_user?: cockpit_be_user_bool_exp | null;
  stakeholders?: jsonb_comparison_exp | null;
  status?: String_comparison_exp | null;
  status_reports?: status_report_status_report_bool_exp | null;
  status_reports_aggregate?: status_report_status_report_aggregate_bool_exp | null;
  strategy?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  theme_tags?: jsonb_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
}

export interface cockpit_be_milestone_aggregate_bool_exp {
  bool_and?: cockpit_be_milestone_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_milestone_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_milestone_aggregate_bool_exp_count | null;
}

export interface cockpit_be_milestone_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_milestone_select_column_cockpit_be_milestone_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_milestone_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_milestone_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_milestone_select_column_cockpit_be_milestone_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_milestone_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_milestone_aggregate_bool_exp_count {
  arguments?: cockpit_be_milestone_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_milestone_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.milestone". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_milestone_bool_exp {
  _and?: cockpit_be_milestone_bool_exp[] | null;
  _not?: cockpit_be_milestone_bool_exp | null;
  _or?: cockpit_be_milestone_bool_exp[] | null;
  actual_end_date?: date_comparison_exp | null;
  description?: String_comparison_exp | null;
  initiative?: cockpit_be_initiative_bool_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  permissions?: cockpit_be_permission_bool_exp | null;
  permissions_aggregate?: cockpit_be_permission_aggregate_bool_exp | null;
  planned_end_date?: date_comparison_exp | null;
  reportable?: Boolean_comparison_exp | null;
  status?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface cockpit_be_permission_aggregate_bool_exp {
  bool_and?: cockpit_be_permission_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_permission_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_permission_aggregate_bool_exp_count | null;
}

export interface cockpit_be_permission_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_permission_select_column_cockpit_be_permission_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_permission_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_permission_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_permission_select_column_cockpit_be_permission_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_permission_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_permission_aggregate_bool_exp_count {
  arguments?: cockpit_be_permission_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_permission_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.permission". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_permission_bool_exp {
  _and?: cockpit_be_permission_bool_exp[] | null;
  _not?: cockpit_be_permission_bool_exp | null;
  _or?: cockpit_be_permission_bool_exp[] | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  manager?: Boolean_comparison_exp | null;
  portfolio_viewer?: Boolean_comparison_exp | null;
  program_manager?: Boolean_comparison_exp | null;
  sponsor?: Boolean_comparison_exp | null;
  statusbericht_teilberechtigte?: Boolean_comparison_exp | null;
  statusbericht_vollberechtigte?: Boolean_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.portfolio". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_portfolio_bool_exp {
  _and?: cockpit_be_portfolio_bool_exp[] | null;
  _not?: cockpit_be_portfolio_bool_exp | null;
  _or?: cockpit_be_portfolio_bool_exp[] | null;
  initiatives?: cockpit_be_initiative_bool_exp | null;
  initiatives_aggregate?: cockpit_be_initiative_aggregate_bool_exp | null;
  jira_project_id?: String_comparison_exp | null;
  key?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  permissions?: cockpit_be_permission_bool_exp | null;
  permissions_aggregate?: cockpit_be_permission_aggregate_bool_exp | null;
}

export interface cockpit_be_problem_aggregate_bool_exp {
  bool_and?: cockpit_be_problem_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_problem_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_problem_aggregate_bool_exp_count | null;
}

export interface cockpit_be_problem_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_problem_select_column_cockpit_be_problem_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_problem_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_problem_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_problem_select_column_cockpit_be_problem_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_problem_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_problem_aggregate_bool_exp_count {
  arguments?: cockpit_be_problem_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_problem_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.problem". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_problem_bool_exp {
  _and?: cockpit_be_problem_bool_exp[] | null;
  _not?: cockpit_be_problem_bool_exp | null;
  _or?: cockpit_be_problem_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  description?: String_comparison_exp | null;
  end_date?: date_comparison_exp | null;
  initiative?: cockpit_be_initiative_bool_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  permissions?: cockpit_be_permission_bool_exp | null;
  permissions_aggregate?: cockpit_be_permission_aggregate_bool_exp | null;
  reportable?: Boolean_comparison_exp | null;
  status?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface cockpit_be_psp_activity_aggregate_bool_exp {
  bool_and?: cockpit_be_psp_activity_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_psp_activity_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_psp_activity_aggregate_bool_exp_count | null;
}

export interface cockpit_be_psp_activity_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_psp_activity_select_column_cockpit_be_psp_activity_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_psp_activity_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_psp_activity_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_psp_activity_select_column_cockpit_be_psp_activity_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_psp_activity_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_psp_activity_aggregate_bool_exp_count {
  arguments?: cockpit_be_psp_activity_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_psp_activity_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.psp_activity". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_psp_activity_bool_exp {
  _and?: cockpit_be_psp_activity_bool_exp[] | null;
  _not?: cockpit_be_psp_activity_bool_exp | null;
  _or?: cockpit_be_psp_activity_bool_exp[] | null;
  description?: String_comparison_exp | null;
  end_date?: date_comparison_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  start_date?: date_comparison_exp | null;
  status?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  top_level?: Boolean_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface cockpit_be_risk_aggregate_bool_exp {
  bool_and?: cockpit_be_risk_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_risk_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_risk_aggregate_bool_exp_count | null;
}

export interface cockpit_be_risk_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_risk_select_column_cockpit_be_risk_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_risk_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_risk_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_risk_select_column_cockpit_be_risk_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_risk_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_risk_aggregate_bool_exp_count {
  arguments?: cockpit_be_risk_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_risk_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.risk". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_risk_bool_exp {
  _and?: cockpit_be_risk_bool_exp[] | null;
  _not?: cockpit_be_risk_bool_exp | null;
  _or?: cockpit_be_risk_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  countermeasures?: cockpit_be_risk_countermeasure_bool_exp | null;
  countermeasures_aggregate?: cockpit_be_risk_countermeasure_aggregate_bool_exp | null;
  damage_impact?: String_comparison_exp | null;
  description?: String_comparison_exp | null;
  estimated_costs?: numeric_comparison_exp | null;
  initiative?: cockpit_be_initiative_bool_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  mitigation?: String_comparison_exp | null;
  next_assessment?: date_comparison_exp | null;
  permissions?: cockpit_be_permission_bool_exp | null;
  permissions_aggregate?: cockpit_be_permission_aggregate_bool_exp | null;
  priority?: String_comparison_exp | null;
  probability?: String_comparison_exp | null;
  reportable?: Boolean_comparison_exp | null;
  status?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface cockpit_be_risk_countermeasure_aggregate_bool_exp {
  bool_and?: cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_and | null;
  bool_or?: cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_or | null;
  count?: cockpit_be_risk_countermeasure_aggregate_bool_exp_count | null;
}

export interface cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_and {
  arguments: cockpit_be_risk_countermeasure_select_column_cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_risk_countermeasure_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_or {
  arguments: cockpit_be_risk_countermeasure_select_column_cockpit_be_risk_countermeasure_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cockpit_be_risk_countermeasure_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cockpit_be_risk_countermeasure_aggregate_bool_exp_count {
  arguments?: cockpit_be_risk_countermeasure_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_risk_countermeasure_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.risk_countermeasure". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_risk_countermeasure_bool_exp {
  _and?: cockpit_be_risk_countermeasure_bool_exp[] | null;
  _not?: cockpit_be_risk_countermeasure_bool_exp | null;
  _or?: cockpit_be_risk_countermeasure_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  end_date?: date_comparison_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  reportable?: Boolean_comparison_exp | null;
  risk?: cockpit_be_risk_bool_exp | null;
  risk_issue_id?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  summary?: String_comparison_exp | null;
  updated_at?: timestamptz_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface cockpit_be_role_aggregate_bool_exp {
  count?: cockpit_be_role_aggregate_bool_exp_count | null;
}

export interface cockpit_be_role_aggregate_bool_exp_count {
  arguments?: cockpit_be_role_select_column[] | null;
  distinct?: boolean | null;
  filter?: cockpit_be_role_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.role". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_role_bool_exp {
  _and?: cockpit_be_role_bool_exp[] | null;
  _not?: cockpit_be_role_bool_exp | null;
  _or?: cockpit_be_role_bool_exp[] | null;
  group_name?: String_comparison_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  role_name?: String_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "cockpit_be.user". All fields are combined with a logical 'AND'.
 */
export interface cockpit_be_user_bool_exp {
  _and?: cockpit_be_user_bool_exp[] | null;
  _not?: cockpit_be_user_bool_exp | null;
  _or?: cockpit_be_user_bool_exp[] | null;
  full_name?: String_comparison_exp | null;
  id?: String_comparison_exp | null;
  roles?: cockpit_be_role_bool_exp | null;
  roles_aggregate?: cockpit_be_role_aggregate_bool_exp | null;
}

export interface cost_control_budget_position_aggregate_bool_exp {
  count?: cost_control_budget_position_aggregate_bool_exp_count | null;
}

export interface cost_control_budget_position_aggregate_bool_exp_count {
  arguments?: cost_control_budget_position_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_budget_position_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.budget_position". All fields are combined with a logical 'AND'.
 */
export interface cost_control_budget_position_bool_exp {
  _and?: cost_control_budget_position_bool_exp[] | null;
  _not?: cost_control_budget_position_bool_exp | null;
  _or?: cost_control_budget_position_bool_exp[] | null;
  amount?: numeric_comparison_exp | null;
  controlling_period_id?: uuid_comparison_exp | null;
  exp_type?: cost_control_expense_type_bool_exp | null;
  expense_type?: cost_control_expense_type_enum_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  period?: cost_control_controlling_period_bool_exp | null;
}

/**
 * Boolean expression to filter rows from the table "cost_control.category". All fields are combined with a logical 'AND'.
 */
export interface cost_control_category_bool_exp {
  _and?: cost_control_category_bool_exp[] | null;
  _not?: cost_control_category_bool_exp | null;
  _or?: cost_control_category_bool_exp[] | null;
  category_options?: cost_control_category_option_bool_exp | null;
  category_options_aggregate?: cost_control_category_option_aggregate_bool_exp | null;
  controlling_plan?: cost_control_controlling_plan_bool_exp | null;
  controlling_plan_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
}

export interface cost_control_category_option_aggregate_bool_exp {
  count?: cost_control_category_option_aggregate_bool_exp_count | null;
}

export interface cost_control_category_option_aggregate_bool_exp_count {
  arguments?: cost_control_category_option_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_category_option_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.category_option". All fields are combined with a logical 'AND'.
 */
export interface cost_control_category_option_bool_exp {
  _and?: cost_control_category_option_bool_exp[] | null;
  _not?: cost_control_category_option_bool_exp | null;
  _or?: cost_control_category_option_bool_exp[] | null;
  category?: cost_control_category_bool_exp | null;
  category_id?: uuid_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
}

export interface cost_control_controlling_period_aggregate_bool_exp {
  count?: cost_control_controlling_period_aggregate_bool_exp_count | null;
}

export interface cost_control_controlling_period_aggregate_bool_exp_count {
  arguments?: cost_control_controlling_period_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_controlling_period_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.controlling_period". All fields are combined with a logical 'AND'.
 */
export interface cost_control_controlling_period_bool_exp {
  _and?: cost_control_controlling_period_bool_exp[] | null;
  _not?: cost_control_controlling_period_bool_exp | null;
  _or?: cost_control_controlling_period_bool_exp[] | null;
  budget_positions?: cost_control_budget_position_bool_exp | null;
  budget_positions_aggregate?: cost_control_budget_position_aggregate_bool_exp | null;
  controlling_plan?: cost_control_controlling_plan_bool_exp | null;
  controlling_plan_id?: uuid_comparison_exp | null;
  cost_plan_positions?: cost_control_cost_plan_position_bool_exp | null;
  cost_plan_positions_aggregate?: cost_control_cost_plan_position_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  overview?: cost_control_controlling_plan_overview_bool_exp | null;
  overview_aggregate?: cost_control_controlling_plan_overview_aggregate_bool_exp | null;
  period?: Int_comparison_exp | null;
  permissions?: cost_control_permission_bool_exp | null;
  permissions_aggregate?: cost_control_permission_aggregate_bool_exp | null;
}

export interface cost_control_controlling_plan_aggregate_bool_exp {
  count?: cost_control_controlling_plan_aggregate_bool_exp_count | null;
}

export interface cost_control_controlling_plan_aggregate_bool_exp_count {
  arguments?: cost_control_controlling_plan_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_controlling_plan_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.controlling_plan". All fields are combined with a logical 'AND'.
 */
export interface cost_control_controlling_plan_bool_exp {
  _and?: cost_control_controlling_plan_bool_exp[] | null;
  _not?: cost_control_controlling_plan_bool_exp | null;
  _or?: cost_control_controlling_plan_bool_exp[] | null;
  id?: uuid_comparison_exp | null;
  initiative?: cockpit_be_initiative_bool_exp | null;
  initiative_issue_id?: String_comparison_exp | null;
  overview?: cost_control_controlling_plan_overview_bool_exp | null;
  overview_aggregate?: cost_control_controlling_plan_overview_aggregate_bool_exp | null;
  periods?: cost_control_controlling_period_bool_exp | null;
  periods_aggregate?: cost_control_controlling_period_aggregate_bool_exp | null;
  permissions?: cost_control_permission_bool_exp | null;
  permissions_aggregate?: cost_control_permission_aggregate_bool_exp | null;
  suppliers?: cost_control_supplier_bool_exp | null;
  suppliers_aggregate?: cost_control_supplier_aggregate_bool_exp | null;
  type?: String_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface cost_control_controlling_plan_overview_aggregate_bool_exp {
  count?: cost_control_controlling_plan_overview_aggregate_bool_exp_count | null;
}

export interface cost_control_controlling_plan_overview_aggregate_bool_exp_count {
  arguments?: cost_control_controlling_plan_overview_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_controlling_plan_overview_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.controlling_plan_overview". All fields are combined with a logical 'AND'.
 */
export interface cost_control_controlling_plan_overview_bool_exp {
  _and?: cost_control_controlling_plan_overview_bool_exp[] | null;
  _not?: cost_control_controlling_plan_overview_bool_exp | null;
  _or?: cost_control_controlling_plan_overview_bool_exp[] | null;
  actual?: numeric_comparison_exp | null;
  budget?: numeric_comparison_exp | null;
  controlling_plan?: cost_control_controlling_plan_bool_exp | null;
  controlling_plan_id?: uuid_comparison_exp | null;
  expense_type?: String_comparison_exp | null;
  forecast?: numeric_comparison_exp | null;
  initiative?: cockpit_be_initiative_bool_exp | null;
  initiative_issue_id?: String_comparison_exp | null;
  name?: String_comparison_exp | null;
  period?: Int_comparison_exp | null;
  permissions?: cost_control_permission_bool_exp | null;
  permissions_aggregate?: cost_control_permission_aggregate_bool_exp | null;
  planned?: numeric_comparison_exp | null;
  type?: String_comparison_exp | null;
}

export interface cost_control_cost_plan_position_aggregate_bool_exp {
  count?: cost_control_cost_plan_position_aggregate_bool_exp_count | null;
}

export interface cost_control_cost_plan_position_aggregate_bool_exp_count {
  arguments?: cost_control_cost_plan_position_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_cost_plan_position_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.cost_plan_position". All fields are combined with a logical 'AND'.
 */
export interface cost_control_cost_plan_position_bool_exp {
  _and?: cost_control_cost_plan_position_bool_exp[] | null;
  _not?: cost_control_cost_plan_position_bool_exp | null;
  _or?: cost_control_cost_plan_position_bool_exp[] | null;
  amount?: numeric_comparison_exp | null;
  controlling_period_id?: uuid_comparison_exp | null;
  expenses?: cost_control_expense_bool_exp | null;
  expenses_aggregate?: cost_control_expense_aggregate_bool_exp | null;
  external_id?: String_comparison_exp | null;
  forecast?: numeric_comparison_exp | null;
  forecasted_hours?: numeric_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  period?: cost_control_controlling_period_bool_exp | null;
  planned_hours?: numeric_comparison_exp | null;
  service?: cost_control_service_bool_exp | null;
  service_id?: uuid_comparison_exp | null;
}

export interface cost_control_expense_aggregate_bool_exp {
  count?: cost_control_expense_aggregate_bool_exp_count | null;
}

export interface cost_control_expense_aggregate_bool_exp_count {
  arguments?: cost_control_expense_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_expense_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.expense". All fields are combined with a logical 'AND'.
 */
export interface cost_control_expense_bool_exp {
  _and?: cost_control_expense_bool_exp[] | null;
  _not?: cost_control_expense_bool_exp | null;
  _or?: cost_control_expense_bool_exp[] | null;
  cost_plan_position?: cost_control_cost_plan_position_bool_exp | null;
  cost_plan_position_id?: uuid_comparison_exp | null;
  description?: String_comparison_exp | null;
  document_date?: date_comparison_exp | null;
  expense_positions?: cost_control_expense_position_bool_exp | null;
  expense_positions_aggregate?: cost_control_expense_position_aggregate_bool_exp | null;
  id?: uuid_comparison_exp | null;
  invoice_date?: date_comparison_exp | null;
}

export interface cost_control_expense_position_aggregate_bool_exp {
  count?: cost_control_expense_position_aggregate_bool_exp_count | null;
}

export interface cost_control_expense_position_aggregate_bool_exp_count {
  arguments?: cost_control_expense_position_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_expense_position_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.expense_position". All fields are combined with a logical 'AND'.
 */
export interface cost_control_expense_position_bool_exp {
  _and?: cost_control_expense_position_bool_exp[] | null;
  _not?: cost_control_expense_position_bool_exp | null;
  _or?: cost_control_expense_position_bool_exp[] | null;
  created_at?: timestamp_comparison_exp | null;
  description?: String_comparison_exp | null;
  expense?: cost_control_expense_bool_exp | null;
  expense_id?: uuid_comparison_exp | null;
  hour_amount?: numeric_comparison_exp | null;
  hourly_rate?: numeric_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  monetary_amount?: numeric_comparison_exp | null;
  profile_name?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "cost_control.expense_type". All fields are combined with a logical 'AND'.
 */
export interface cost_control_expense_type_bool_exp {
  _and?: cost_control_expense_type_bool_exp[] | null;
  _not?: cost_control_expense_type_bool_exp | null;
  _or?: cost_control_expense_type_bool_exp[] | null;
  type?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "cost_control_expense_type_enum". All fields are combined with logical 'AND'.
 */
export interface cost_control_expense_type_enum_comparison_exp {
  _eq?: cost_control_expense_type_enum | null;
  _in?: cost_control_expense_type_enum[] | null;
  _is_null?: boolean | null;
  _neq?: cost_control_expense_type_enum | null;
  _nin?: cost_control_expense_type_enum[] | null;
}

export interface cost_control_permission_aggregate_bool_exp {
  bool_and?: cost_control_permission_aggregate_bool_exp_bool_and | null;
  bool_or?: cost_control_permission_aggregate_bool_exp_bool_or | null;
  count?: cost_control_permission_aggregate_bool_exp_count | null;
}

export interface cost_control_permission_aggregate_bool_exp_bool_and {
  arguments: cost_control_permission_select_column_cost_control_permission_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: cost_control_permission_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cost_control_permission_aggregate_bool_exp_bool_or {
  arguments: cost_control_permission_select_column_cost_control_permission_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: cost_control_permission_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface cost_control_permission_aggregate_bool_exp_count {
  arguments?: cost_control_permission_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_permission_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.permission". All fields are combined with a logical 'AND'.
 */
export interface cost_control_permission_bool_exp {
  _and?: cost_control_permission_bool_exp[] | null;
  _not?: cost_control_permission_bool_exp | null;
  _or?: cost_control_permission_bool_exp[] | null;
  controlling_plan_id?: uuid_comparison_exp | null;
  issue_id?: String_comparison_exp | null;
  jira_project_id?: String_comparison_exp | null;
  manager?: Boolean_comparison_exp | null;
  portfolio_viewer?: Boolean_comparison_exp | null;
  program_manager?: Boolean_comparison_exp | null;
  sponsor?: Boolean_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

export interface cost_control_profile_aggregate_bool_exp {
  count?: cost_control_profile_aggregate_bool_exp_count | null;
}

export interface cost_control_profile_aggregate_bool_exp_count {
  arguments?: cost_control_profile_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_profile_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.profile". All fields are combined with a logical 'AND'.
 */
export interface cost_control_profile_bool_exp {
  _and?: cost_control_profile_bool_exp[] | null;
  _not?: cost_control_profile_bool_exp | null;
  _or?: cost_control_profile_bool_exp[] | null;
  external_id?: String_comparison_exp | null;
  hourly_rate?: numeric_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  supplier?: cost_control_supplier_bool_exp | null;
  supplier_id?: uuid_comparison_exp | null;
}

export interface cost_control_service_aggregate_bool_exp {
  count?: cost_control_service_aggregate_bool_exp_count | null;
}

export interface cost_control_service_aggregate_bool_exp_count {
  arguments?: cost_control_service_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_service_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.service". All fields are combined with a logical 'AND'.
 */
export interface cost_control_service_bool_exp {
  _and?: cost_control_service_bool_exp[] | null;
  _not?: cost_control_service_bool_exp | null;
  _or?: cost_control_service_bool_exp[] | null;
  categories?: cost_control_service_category_bool_exp | null;
  categories_aggregate?: cost_control_service_category_aggregate_bool_exp | null;
  controlling_plan?: cost_control_controlling_plan_bool_exp | null;
  controlling_plan_id?: uuid_comparison_exp | null;
  cost_plan_positions?: cost_control_cost_plan_position_bool_exp | null;
  cost_plan_positions_aggregate?: cost_control_cost_plan_position_aggregate_bool_exp | null;
  description?: String_comparison_exp | null;
  expense_type?: cost_control_expense_type_enum_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  supplier?: cost_control_supplier_bool_exp | null;
  supplier_id?: uuid_comparison_exp | null;
}

export interface cost_control_service_category_aggregate_bool_exp {
  count?: cost_control_service_category_aggregate_bool_exp_count | null;
}

export interface cost_control_service_category_aggregate_bool_exp_count {
  arguments?: cost_control_service_category_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_service_category_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.service_category". All fields are combined with a logical 'AND'.
 */
export interface cost_control_service_category_bool_exp {
  _and?: cost_control_service_category_bool_exp[] | null;
  _not?: cost_control_service_category_bool_exp | null;
  _or?: cost_control_service_category_bool_exp[] | null;
  category_option?: cost_control_category_option_bool_exp | null;
  category_option_id?: uuid_comparison_exp | null;
  service?: cost_control_service_bool_exp | null;
  service_id?: uuid_comparison_exp | null;
}

export interface cost_control_supplier_aggregate_bool_exp {
  count?: cost_control_supplier_aggregate_bool_exp_count | null;
}

export interface cost_control_supplier_aggregate_bool_exp_count {
  arguments?: cost_control_supplier_select_column[] | null;
  distinct?: boolean | null;
  filter?: cost_control_supplier_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "cost_control.supplier". All fields are combined with a logical 'AND'.
 */
export interface cost_control_supplier_bool_exp {
  _and?: cost_control_supplier_bool_exp[] | null;
  _not?: cost_control_supplier_bool_exp | null;
  _or?: cost_control_supplier_bool_exp[] | null;
  controlling_plan?: cost_control_controlling_plan_bool_exp | null;
  controlling_plan_id?: uuid_comparison_exp | null;
  external_id?: String_comparison_exp | null;
  group?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  profiles?: cost_control_profile_bool_exp | null;
  profiles_aggregate?: cost_control_profile_aggregate_bool_exp | null;
  services?: cost_control_service_bool_exp | null;
  services_aggregate?: cost_control_service_aggregate_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'.
 */
export interface date_comparison_exp {
  _eq?: graphql_date | null;
  _gt?: graphql_date | null;
  _gte?: graphql_date | null;
  _in?: graphql_date[] | null;
  _is_null?: boolean | null;
  _lt?: graphql_date | null;
  _lte?: graphql_date | null;
  _neq?: graphql_date | null;
  _nin?: graphql_date[] | null;
}

export interface jsonb_cast_exp {
  String?: String_comparison_exp | null;
}

/**
 * Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'.
 */
export interface jsonb_comparison_exp {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: graphql_jsonb | null;
  _contains?: graphql_jsonb | null;
  _eq?: graphql_jsonb | null;
  _gt?: graphql_jsonb | null;
  _gte?: graphql_jsonb | null;
  _has_key?: string | null;
  _has_keys_all?: string[] | null;
  _has_keys_any?: string[] | null;
  _in?: graphql_jsonb[] | null;
  _is_null?: boolean | null;
  _lt?: graphql_jsonb | null;
  _lte?: graphql_jsonb | null;
  _neq?: graphql_jsonb | null;
  _nin?: graphql_jsonb[] | null;
}

/**
 * Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'.
 */
export interface numeric_comparison_exp {
  _eq?: graphql_numeric | null;
  _gt?: graphql_numeric | null;
  _gte?: graphql_numeric | null;
  _in?: graphql_numeric[] | null;
  _is_null?: boolean | null;
  _lt?: graphql_numeric | null;
  _lte?: graphql_numeric | null;
  _neq?: graphql_numeric | null;
  _nin?: graphql_numeric[] | null;
}

export interface status_report_change_request_aggregate_bool_exp {
  count?: status_report_change_request_aggregate_bool_exp_count | null;
}

export interface status_report_change_request_aggregate_bool_exp_count {
  arguments?: status_report_change_request_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_change_request_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.change_request". All fields are combined with a logical 'AND'.
 */
export interface status_report_change_request_bool_exp {
  _and?: status_report_change_request_bool_exp[] | null;
  _not?: status_report_change_request_bool_exp | null;
  _or?: status_report_change_request_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  description?: String_comparison_exp | null;
  external_id?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  reporter?: String_comparison_exp | null;
  reporter_user?: cockpit_be_user_bool_exp | null;
  status?: String_comparison_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  status_report_id?: uuid_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface status_report_milestone_aggregate_bool_exp {
  count?: status_report_milestone_aggregate_bool_exp_count | null;
}

export interface status_report_milestone_aggregate_bool_exp_count {
  arguments?: status_report_milestone_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_milestone_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.milestone". All fields are combined with a logical 'AND'.
 */
export interface status_report_milestone_bool_exp {
  _and?: status_report_milestone_bool_exp[] | null;
  _not?: status_report_milestone_bool_exp | null;
  _or?: status_report_milestone_bool_exp[] | null;
  actual_date?: date_comparison_exp | null;
  description?: String_comparison_exp | null;
  external_id?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  planned_date?: date_comparison_exp | null;
  status?: String_comparison_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  status_report_id?: uuid_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface status_report_permission_aggregate_bool_exp {
  bool_and?: status_report_permission_aggregate_bool_exp_bool_and | null;
  bool_or?: status_report_permission_aggregate_bool_exp_bool_or | null;
  count?: status_report_permission_aggregate_bool_exp_count | null;
}

export interface status_report_permission_aggregate_bool_exp_bool_and {
  arguments: status_report_permission_select_column_status_report_permission_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: status_report_permission_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface status_report_permission_aggregate_bool_exp_bool_or {
  arguments: status_report_permission_select_column_status_report_permission_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: status_report_permission_bool_exp | null;
  predicate: Boolean_comparison_exp;
}

export interface status_report_permission_aggregate_bool_exp_count {
  arguments?: status_report_permission_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_permission_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.permission". All fields are combined with a logical 'AND'.
 */
export interface status_report_permission_bool_exp {
  _and?: status_report_permission_bool_exp[] | null;
  _not?: status_report_permission_bool_exp | null;
  _or?: status_report_permission_bool_exp[] | null;
  manager?: Boolean_comparison_exp | null;
  portfolio_viewer?: Boolean_comparison_exp | null;
  program_manager?: Boolean_comparison_exp | null;
  report_id?: uuid_comparison_exp | null;
  sponsor?: Boolean_comparison_exp | null;
  statusbericht_teilberechtigte?: Boolean_comparison_exp | null;
  statusbericht_vollberechtigte?: Boolean_comparison_exp | null;
  user_id?: String_comparison_exp | null;
}

export interface status_report_problem_aggregate_bool_exp {
  count?: status_report_problem_aggregate_bool_exp_count | null;
}

export interface status_report_problem_aggregate_bool_exp_count {
  arguments?: status_report_problem_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_problem_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.problem". All fields are combined with a logical 'AND'.
 */
export interface status_report_problem_bool_exp {
  _and?: status_report_problem_bool_exp[] | null;
  _not?: status_report_problem_bool_exp | null;
  _or?: status_report_problem_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  due_date?: date_comparison_exp | null;
  external_id?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  name?: String_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  status?: String_comparison_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  status_report_id?: uuid_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface status_report_project_costs_aggregate_bool_exp {
  count?: status_report_project_costs_aggregate_bool_exp_count | null;
}

export interface status_report_project_costs_aggregate_bool_exp_count {
  arguments?: status_report_project_costs_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_project_costs_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.project_costs". All fields are combined with a logical 'AND'.
 */
export interface status_report_project_costs_bool_exp {
  _and?: status_report_project_costs_bool_exp[] | null;
  _not?: status_report_project_costs_bool_exp | null;
  _or?: status_report_project_costs_bool_exp[] | null;
  amount?: numeric_comparison_exp | null;
  date?: date_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  status_report_id?: uuid_comparison_exp | null;
  type?: String_comparison_exp | null;
}

export interface status_report_project_state_aggregate_bool_exp {
  count?: status_report_project_state_aggregate_bool_exp_count | null;
}

export interface status_report_project_state_aggregate_bool_exp_count {
  arguments?: status_report_project_state_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_project_state_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.project_state". All fields are combined with a logical 'AND'.
 */
export interface status_report_project_state_bool_exp {
  _and?: status_report_project_state_bool_exp[] | null;
  _not?: status_report_project_state_bool_exp | null;
  _or?: status_report_project_state_bool_exp[] | null;
  comment?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  state?: String_comparison_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  status_report_id?: uuid_comparison_exp | null;
  type?: String_comparison_exp | null;
}

export interface status_report_risk_aggregate_bool_exp {
  count?: status_report_risk_aggregate_bool_exp_count | null;
}

export interface status_report_risk_aggregate_bool_exp_count {
  arguments?: status_report_risk_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_risk_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.risk". All fields are combined with a logical 'AND'.
 */
export interface status_report_risk_bool_exp {
  _and?: status_report_risk_bool_exp[] | null;
  _not?: status_report_risk_bool_exp | null;
  _or?: status_report_risk_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  countermeasures?: status_report_risk_countermeasure_bool_exp | null;
  countermeasures_aggregate?: status_report_risk_countermeasure_aggregate_bool_exp | null;
  damage_extent?: String_comparison_exp | null;
  description?: String_comparison_exp | null;
  estimated_cost?: numeric_comparison_exp | null;
  external_id?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  next_assessment?: date_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  probability_of_occurrence?: String_comparison_exp | null;
  status?: String_comparison_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  status_report_id?: uuid_comparison_exp | null;
  url?: String_comparison_exp | null;
}

export interface status_report_risk_countermeasure_aggregate_bool_exp {
  count?: status_report_risk_countermeasure_aggregate_bool_exp_count | null;
}

export interface status_report_risk_countermeasure_aggregate_bool_exp_count {
  arguments?: status_report_risk_countermeasure_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_risk_countermeasure_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.risk_countermeasure". All fields are combined with a logical 'AND'.
 */
export interface status_report_risk_countermeasure_bool_exp {
  _and?: status_report_risk_countermeasure_bool_exp[] | null;
  _not?: status_report_risk_countermeasure_bool_exp | null;
  _or?: status_report_risk_countermeasure_bool_exp[] | null;
  assignee?: String_comparison_exp | null;
  assignee_user?: cockpit_be_user_bool_exp | null;
  description?: String_comparison_exp | null;
  end_date?: date_comparison_exp | null;
  external_id?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  risk?: status_report_risk_bool_exp | null;
  risk_id?: uuid_comparison_exp | null;
  status?: String_comparison_exp | null;
}

export interface status_report_status_report_aggregate_bool_exp {
  count?: status_report_status_report_aggregate_bool_exp_count | null;
}

export interface status_report_status_report_aggregate_bool_exp_count {
  arguments?: status_report_status_report_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_status_report_bool_exp | null;
  predicate: Int_comparison_exp;
}

export interface status_report_status_report_attachment_aggregate_bool_exp {
  count?: status_report_status_report_attachment_aggregate_bool_exp_count | null;
}

export interface status_report_status_report_attachment_aggregate_bool_exp_count {
  arguments?: status_report_status_report_attachment_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_status_report_attachment_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.status_report_attachment". All fields are combined with a logical 'AND'.
 */
export interface status_report_status_report_attachment_bool_exp {
  _and?: status_report_status_report_attachment_bool_exp[] | null;
  _not?: status_report_status_report_attachment_bool_exp | null;
  _or?: status_report_status_report_attachment_bool_exp[] | null;
  filename?: String_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  statusreport_id?: uuid_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "status_report.status_report". All fields are combined with a logical 'AND'.
 */
export interface status_report_status_report_bool_exp {
  _and?: status_report_status_report_bool_exp[] | null;
  _not?: status_report_status_report_bool_exp | null;
  _or?: status_report_status_report_bool_exp[] | null;
  actual_costs?: numeric_comparison_exp | null;
  actual_end_date?: date_comparison_exp | null;
  actual_start_date?: date_comparison_exp | null;
  attachments?: status_report_status_report_attachment_bool_exp | null;
  attachments_aggregate?: status_report_status_report_attachment_aggregate_bool_exp | null;
  change_requests?: status_report_change_request_bool_exp | null;
  change_requests_aggregate?: status_report_change_request_aggregate_bool_exp | null;
  change_requests_comment?: String_comparison_exp | null;
  description?: String_comparison_exp | null;
  external_program_id?: String_comparison_exp | null;
  external_project_id?: String_comparison_exp | null;
  id?: uuid_comparison_exp | null;
  initiative?: cockpit_be_initiative_bool_exp | null;
  initiative_issue_id?: String_comparison_exp | null;
  milestones?: status_report_milestone_bool_exp | null;
  milestones_aggregate?: status_report_milestone_aggregate_bool_exp | null;
  milestones_comment?: String_comparison_exp | null;
  next_milestones?: String_comparison_exp | null;
  past_successes?: String_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  planned_costs?: numeric_comparison_exp | null;
  planned_end_date?: date_comparison_exp | null;
  planned_start_date?: date_comparison_exp | null;
  portfolio?: String_comparison_exp | null;
  problems?: status_report_problem_bool_exp | null;
  problems_aggregate?: status_report_problem_aggregate_bool_exp | null;
  problems_comment?: String_comparison_exp | null;
  project_costs?: status_report_project_costs_bool_exp | null;
  project_costs_aggregate?: status_report_project_costs_aggregate_bool_exp | null;
  project_manager?: String_comparison_exp | null;
  project_manager_user?: cockpit_be_user_bool_exp | null;
  project_name?: String_comparison_exp | null;
  project_phase?: String_comparison_exp | null;
  project_states?: status_report_project_state_bool_exp | null;
  project_states_aggregate?: status_report_project_state_aggregate_bool_exp | null;
  project_status?: String_comparison_exp | null;
  report_date?: date_comparison_exp | null;
  report_title?: String_comparison_exp | null;
  risks?: status_report_risk_bool_exp | null;
  risks_aggregate?: status_report_risk_aggregate_bool_exp | null;
  risks_comment?: String_comparison_exp | null;
  state?: String_comparison_exp | null;
  state_histories?: status_report_status_report_history_bool_exp | null;
  state_histories_aggregate?: status_report_status_report_history_aggregate_bool_exp | null;
  status_report_attachments?: status_report_status_report_attachment_bool_exp | null;
  status_report_attachments_aggregate?: status_report_status_report_attachment_aggregate_bool_exp | null;
  status_report_state?: status_report_status_report_state_bool_exp | null;
}

export interface status_report_status_report_history_aggregate_bool_exp {
  count?: status_report_status_report_history_aggregate_bool_exp_count | null;
}

export interface status_report_status_report_history_aggregate_bool_exp_count {
  arguments?: status_report_status_report_history_select_column[] | null;
  distinct?: boolean | null;
  filter?: status_report_status_report_history_bool_exp | null;
  predicate: Int_comparison_exp;
}

/**
 * Boolean expression to filter rows from the table "status_report.status_report_history". All fields are combined with a logical 'AND'.
 */
export interface status_report_status_report_history_bool_exp {
  _and?: status_report_status_report_history_bool_exp[] | null;
  _not?: status_report_status_report_history_bool_exp | null;
  _or?: status_report_status_report_history_bool_exp[] | null;
  id?: uuid_comparison_exp | null;
  new_state?: String_comparison_exp | null;
  permissions?: status_report_permission_bool_exp | null;
  permissions_aggregate?: status_report_permission_aggregate_bool_exp | null;
  status_report?: status_report_status_report_bool_exp | null;
  status_report_id?: uuid_comparison_exp | null;
  status_report_state?: status_report_status_report_state_bool_exp | null;
  timestamp?: timestamptz_comparison_exp | null;
  user?: String_comparison_exp | null;
}

/**
 * Boolean expression to filter rows from the table "status_report.status_report_state". All fields are combined with a logical 'AND'.
 */
export interface status_report_status_report_state_bool_exp {
  _and?: status_report_status_report_state_bool_exp[] | null;
  _not?: status_report_status_report_state_bool_exp | null;
  _or?: status_report_status_report_state_bool_exp[] | null;
  state?: String_comparison_exp | null;
  status_report_histories?: status_report_status_report_history_bool_exp | null;
  status_report_histories_aggregate?: status_report_status_report_history_aggregate_bool_exp | null;
  status_reports?: status_report_status_report_bool_exp | null;
  status_reports_aggregate?: status_report_status_report_aggregate_bool_exp | null;
}

/**
 * Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'.
 */
export interface timestamp_comparison_exp {
  _eq?: graphql_timestamp | null;
  _gt?: graphql_timestamp | null;
  _gte?: graphql_timestamp | null;
  _in?: graphql_timestamp[] | null;
  _is_null?: boolean | null;
  _lt?: graphql_timestamp | null;
  _lte?: graphql_timestamp | null;
  _neq?: graphql_timestamp | null;
  _nin?: graphql_timestamp[] | null;
}

/**
 * Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'.
 */
export interface timestamptz_comparison_exp {
  _eq?: graphql_timestamptz | null;
  _gt?: graphql_timestamptz | null;
  _gte?: graphql_timestamptz | null;
  _in?: graphql_timestamptz[] | null;
  _is_null?: boolean | null;
  _lt?: graphql_timestamptz | null;
  _lte?: graphql_timestamptz | null;
  _neq?: graphql_timestamptz | null;
  _nin?: graphql_timestamptz[] | null;
}

/**
 * Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'.
 */
export interface uuid_comparison_exp {
  _eq?: graphql_uuid | null;
  _gt?: graphql_uuid | null;
  _gte?: graphql_uuid | null;
  _in?: graphql_uuid[] | null;
  _is_null?: boolean | null;
  _lt?: graphql_uuid | null;
  _lte?: graphql_uuid | null;
  _neq?: graphql_uuid | null;
  _nin?: graphql_uuid[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
