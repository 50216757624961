import React from "react"
import styles from "./StatusReport.module.scss"
import ReportOverview from "./components/ReportOverview"
import ReportSummary from "./components/ReportSummary"
import CurrentState from "./components/CurrentStatus"
import {Costs} from "./components/CostsLineGraph"
import {gql, useQuery} from "@apollo/client"
import CircularProgress from "@mui/material/CircularProgress"
import {Problems} from "./components/Problems"
import ReportAttachments from "./components/ReportAttachments"
import {toStatusReportData} from "./statusReportData"
import StateHistory from "./components/StatusHistory"
import {GetReport, GetReportVariables} from "./types/GetReport"
import {Section} from "./components/Section"
import {useTranslation} from "react-i18next"
import {ReleasedOrInReviewFragment, ReleasedOrInReviewFragmentVertraulich, UnreleasedOrRejectedFragment} from "./reportFragments"
import {useUser} from "../../../auth/UserProvider"
import {useAuth} from "../../../auth/AuthProvider"
import {StatusOfProjects} from "./program/StatusOfProjects"
import {getPageMargins} from "../../../utils/printStyles"
import {ChangeRequests} from "./components/ChangeRequests"
import {Milestones} from "./components/Milestones"
import {Risks} from "./components/Risks"

export const getReport = gql`
    query GetReport($id: uuid!, $reduced_report: Boolean!) {
        releasedOrInReview_report: status_report_status_report(
            where: {id: {_eq: $id}, state: {_in: ["released", "in_review", "canceled"]}}
        ) {
            ...ReleasedOrInReviewData
            ...ReleasedOrInReviewDataVertraulich @skip(if: $reduced_report)
        }
        unreleasedOrRejected_report: status_report_status_report(where: {id: {_eq: $id}, state: {_in: ["unreleased", "rejected"]}}) {
            ...UnreleasedOrRejectedData
        }
        costs: status_report_costs(status_report_id: $id) @skip(if: $reduced_report) {
            report_date
            report_date_as_skalar
            total_actual
            total_planned
            url
            actual {
                amount
                date
                date_as_skalar
            }
            planned {
                amount
                date
                date_as_skalar
            }
        }
        history: status_report_status_report_history(where: {status_report_id: {_eq: $id}}) {
            id
            new_state
            timestamp
        }
        status_history: status_report_status_report_by_pk(id: $id) {
            initiative {
                status_reports(
                    where: {_and: [{id: {_neq: $id}}, {state: {_in: ["released"]}}]}, 
                    order_by: {report_date: desc}, limit: 5) {
                    id
                    report_date
                    project_states {
                        state
                        type
                        id
                    }
                }
            }
        }
    }
    ${ReleasedOrInReviewFragment}
    ${ReleasedOrInReviewFragmentVertraulich}
    ${UnreleasedOrRejectedFragment}
`

interface Props {
    selectedReportId: string
}

export const StatusReport = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {selectedReportId} = props
    const {t} = useTranslation("translations")

    const user = useUser()
    const auth = useAuth()

    const {data, loading, error, refetch} = useQuery<GetReport, GetReportVariables>(getReport, {
        variables: {
            id: selectedReportId,
            reduced_report: !user.isAllowedToSeeFullStatusReport(selectedReportId),
        },
    })

    if (error) throw Error(error.message)

    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-spinner">
                <CircularProgress />
            </div>
        )
    }

    const report = toStatusReportData(data)

    const isReportEditable = report.isEditableBy(auth.current())

    return (
        <div className={styles.report}>
            {/* This line below is for the print-view */}
            <style>{getPageMargins()}</style>
            <div ref={ref}>
                {/* //OVERVIEW */}
                <Section title={t("overview")}>
                    <ReportOverview report={report} queryRefetch={refetch} />
                </Section>

                {/* //SUMMARY */}
                <Section title={t("summary")}>
                    <ReportSummary report={report} queryRefetch={refetch} />
                </Section>

                {/* //ATTACHMENTS */}
                <Section title={t("attachments")}>
                    <ReportAttachments report={report} queryRefetch={refetch} />
                </Section>

                {/* //COSTS & RESOURCE */}
                {user.isAllowedToSeeFullStatusReport(report.id) && (
                    <Section title={t("costs-plancosts-vs-actualcosts")}>
                        <Costs costs={data.costs} />
                    </Section>
                )}

                {/* CURRENT STATUS */}
                <Section title={t("current-status")}>
                    <CurrentState reportId={report.id} isReportEditable={isReportEditable} />
                </Section>

                {/* STATUS HISTORY*/}
                <Section title={t("status-history")}>
                    <StateHistory states={report.states} reportDate={report.overview.report_date} />
                </Section>

                {/* IN PROGRAM REPORT ONLY - STATUS OF THE INDIVIDUAL PROJECTS*/}
                {report.type === "program" && (
                    <Section title={t("program-report-status-of-individual-projects")}>
                        <StatusOfProjects report={report} />
                    </Section>
                )}

                {/* MILESTONES*/}
                <Section title={t("milestones")}>
                    <Milestones report={report} queryRefetch={refetch} />
                </Section>

                {/* RISKS*/}
                {user.isAllowedToSeeFullStatusReport(report.id) && (
                    <Section title={t("risks")}>
                        <Risks report={report} queryRefetch={refetch} />
                    </Section>
                )}

                {/* Problems */}
                {user.isAllowedToSeeFullStatusReport(report.id) && (
                    <Section title={t("problems")}>
                        <Problems report={report} queryRefetch={refetch} />
                    </Section>
                )}

                {/* Change requests */}
                {user.isAllowedToSeeFullStatusReport(report.id) && (
                    <Section title={t("requests_for_changes")}>
                        <ChangeRequests report={report} queryRefetch={refetch} />
                    </Section>
                )}
                <div className={styles.footnotes}>
                    <div>
                        <span>{t("status-report-first-footnote")}</span>
                    </div>
                    <div>
                        <span>{t("status-report-second-footnote")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
})
