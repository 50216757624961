import React from "react"
import styles from "./FilterDropdownsSection.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import IconButton from "@mui/material/IconButton"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {useTranslation} from "react-i18next"
import {Tooltip} from "@mui/material"
import {CSVLink} from "react-csv"

export type CsvData<K extends string> = {
    header: Record<K, string> // Map keys to their display names
    data: Record<K, string>[] // Use clean keys for data
}

interface Props {
    data: CsvData<any>
}

export const ExportCSV: React.FC<Props> = ({data}) => {
    const {t} = useTranslation("translations")

    const headers = data.data.length
        ? Object.entries(data.header).map(([key, value]) => ({
              label: value,
              key: key,
          }))
        : []

    return (
        <Tooltip title={<span>{t("export-csv")}</span>}>
            <IconButton className={styles.actionIcon}>
                <CSVLink data={data.data} filename={"Lieferobjekt.csv"} target="_blank" className={styles.csvLink} headers={headers}>
                    <FontAwesomeIcon icon={["far", "file-export"] as IconProp} size="xs" />
                </CSVLink>
            </IconButton>
        </Tooltip>
    )
}
