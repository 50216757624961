import React, {useCallback, useEffect, useRef, useState} from "react"
import {getPageMargins} from "../../../utils/printStyles"
import PageWrapper from "../../../components/PageWrapper"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {useTranslation} from "react-i18next"
import FilterDropdownsSection from "../../../components/FilterDropdownsSection"
import {useReactToPrint} from "react-to-print"
import {CircularProgress, SelectChangeEvent} from "@mui/material"
import {PortfolioDropdown} from "../dropdowns"
import {gql, useQuery} from "@apollo/client"
import {capitalizeFirstLetter} from "../../../utils/strings"
import {RiskReportData} from "./riskReportData"
import {GetRiskReportFilterOptions} from "./types/GetRiskReportFilterOptions"
import RiskReportMainContent from "./RiskReportMainContent"
import RiskReportPrintVersion from "./RiskReportPrintVersion"
import {GetRisksByInitiative} from "./types/GetRisksByInitiative"
import {
    ProjektsAndProgramsDropdown,
    RiskLevelDropdown,
    RiskProbabilityDropdown,
    RisksPriorityDropdown,
} from "./riskReportDropdowns"
import {isPresent} from "../../../utils/arrays"

const getRiskReportFilterOptions = gql`
    query GetRiskReportFilterOptions($whereInitiative: cockpit_be_initiative_bool_exp) {
        portfolio: cockpit_be_portfolio {
            name
            jira_project_id
        }
        initiatives: cockpit_be_initiative(where: $whereInitiative) {
            summary
            issue_id
        }
    }
`

const getRisksByInitiative = gql`
    query GetRisksByInitiative($whereRisks: cockpit_be_risk_bool_exp, $whereInitiative: cockpit_be_initiative_bool_exp) {
        initiatives: cockpit_be_initiative(where: $whereInitiative) {
            jira_project_id
            initiative_type
            portfolio {
                name
                jira_project_id
            }
            issue_id
            summary
            risks(where: $whereRisks) {
                assignee_user {
                    id
                    full_name
                }
                damage_impact
                description
                estimated_costs
                jira_project_id
                issue_id
                next_assessment
                probability
                status
                summary
                priority
                countermeasures {
                    assignee_user {
                        id
                        full_name
                    }
                    summary
                    end_date
                    issue_id
                    status
                }
                initiative {
                    summary
                }
            }
        }
    }
`

interface Props {
}

export const RiskReport: React.FC<Props> = () => {
    const {t} = useTranslation("translations")
    const [printing, setPrinting] = useState<boolean>(false)
    const printStatusReportRef = useRef<HTMLDivElement | null>(null)
    const [overviewFilterOptions, setOverviewFilterOptions] = useState({
        portfolio: "",
        project_or_program: "",
        damage_impact: "",
        probability: "",
        priority: "",
    })

    let portfolioVariable = ""
    let projecktOrProgramId = ""
    let filterVariables = {}
    Object.entries(overviewFilterOptions)
        .filter((x) => x[1] !== "")
        .forEach((x) => {
            if (x[0] === "portfolio") {
                portfolioVariable = x[1]
            } else if (x[0] === "project_or_program") {
                projecktOrProgramId = x[1]
            } else {
                filterVariables = {
                    ...filterVariables,
                    [x[0]]: {_eq: x[1]},
                }
            }
        })

    useEffect(() => {
        if (overviewFilterOptions.project_or_program === "") {
            setOverviewFilterOptions({...overviewFilterOptions, portfolio: ""})
        }
        if (overviewFilterOptions.project_or_program) {
            const portfolio =
                riskData.initiatives.find((initiative) => initiative.issue_id === overviewFilterOptions.project_or_program)?.portfolio
                    ?.jira_project_id || ""
            setOverviewFilterOptions({...overviewFilterOptions, portfolio: portfolio})
        }
    }, [overviewFilterOptions.project_or_program])

    const handleChange = (event: SelectChangeEvent) => {
        setOverviewFilterOptions({...overviewFilterOptions, [event.target.name]: event.target.value})
    }

    const handlePrint = useReactToPrint({
        content: () => printStatusReportRef.current,
        pageStyle: `@media print {  .printDisplay {display: block !important;}}`,
    })

    const createOptions = useCallback(
        (array: {name: string | null; id: string | null}[], uppercase?: boolean) => [
            {name: `${t("all")}`, id: ""},
            ...array.filter(x => isPresent((x.name) && isPresent(x.id))).map((x) => {
                if (uppercase) {
                    return {name: capitalizeFirstLetter(x.name!), id: x.id!}
                } else {
                    return {name: x.name!, id: x.id!}
                }
            }),
        ],
        [t],
    )

    const riskDataWhereVariable = () => {
        return {
            risks_aggregate: {count: {predicate: {_gt: 0}}},
            ...(projecktOrProgramId && {_and: {issue_id: {_eq: projecktOrProgramId}}}),
            ...(portfolioVariable && {_and: {portfolio: {jira_project_id: {_eq: portfolioVariable}}}}),
        }
    }

    const filterOptionsWhereVariable = () => {
        return {
            risks_aggregate: {count: {predicate: {_gt: 0}}},
            ...(portfolioVariable && {_and: {portfolio: {jira_project_id: {_eq: portfolioVariable}}}}),
        }
    }

    const {
        data: filterOptionsData,
        loading: filterOptionsLoading,
        error: filterOptionsError,
    } = useQuery<GetRiskReportFilterOptions>(getRiskReportFilterOptions, {
        variables: {
            whereInitiative: filterOptionsWhereVariable(),
        },
    })

    const {data, loading, error} = useQuery<GetRisksByInitiative>(getRisksByInitiative, {
        variables: {
            whereRisks: filterVariables,
            whereInitiative: riskDataWhereVariable(),
        },
    })

    if (!filterOptionsData || !data) return <></>
    if (error || filterOptionsError) throw Error(error?.message || filterOptionsError?.message)

    if (loading || filterOptionsLoading)
        return (
            <div>
                <p>{t("loading")}</p>
                <CircularProgress />
            </div>
        )

    const portfolios =
        filterOptionsData?.portfolio.map((port) => {
            return {name: port.name, id: port.jira_project_id}
        }) ?? []

    const riskData = new RiskReportData(data.initiatives)
    const initiatives = filterOptionsData.initiatives.flatMap((initiative) => {
        return {name: initiative.summary ?? "", id: initiative.issue_id!}
    })

    const dropdowns: any[] = [
        PortfolioDropdown(createOptions(portfolios)),
        ProjektsAndProgramsDropdown(createOptions(initiatives)),
        RiskLevelDropdown(t),
        RiskProbabilityDropdown(t),
        RisksPriorityDropdown(t),
    ]

    const handlePrintingReport = () => {
        setPrinting(true)
        //NOTE: The Nivo charts would not load in time when simply using "display: none" as suggested by react-to-print to print components that are only viewable during print and not at all on @media: screen. Therefore, this timeout to toggle the printing state will switch the view of the page to the "print friendly" component for a moment to allow for the DOM to load the charts. Without this the page will have all charts show as empty in the print-preview.
        setTimeout(() => {
            handlePrint()
            setPrinting(false)
        }, 200)
    }

    return (
        <>
            <style>{getPageMargins()}</style>
            <div ref={printStatusReportRef}>
                <PageWrapper headerTitle={t("risk-report")} icon={"list" as IconProp}>
                    <FilterDropdownsSection
                        handleChange={handleChange}
                        handlePrint={handlePrintingReport}
                        filterValues={overviewFilterOptions}
                        dropdowns={dropdowns}
                    />
                    {/* //NOTE: ReportPrintVersion is the PortfolioReport redone entirely without Mui components. The combination of
                    react-to-print, SVG Nivo Charts & Mui Grid system (which is needed for the graphs on @media: screen view) was causing
                    the graphs to completely jump and leave their container divs in print-preview. By creating a conditional print-friendly
                    component for the PortfolioReport that only shows up as the document is being printed was the the only way to solve this
                    issue. */}
                    <>{printing ? <RiskReportPrintVersion data={riskData} /> :
                        <RiskReportMainContent data={riskData} />}</>
                </PageWrapper>
            </div>
        </>
    )
}
