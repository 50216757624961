import {gql} from "graphql-tag"

export const ReleasedOrInReviewFragment = gql`
    fragment ReleasedOrInReviewData on status_report_status_report {
        actual_end_date
        actual_start_date
        status_report_attachments {
            filename
            statusreport_id
        }
        description
        id
        next_milestones
        past_successes
        planned_start_date
        planned_end_date
        portfolio
        project_manager_user {
            id
            full_name
        }
        project_name
        project_phase
        project_status
        report_date
        state
        report_title
        state_histories {
            new_state
            timestamp
        }
        project_states {
            id
            state
            type
            status_report_id
            status_report {
                report_date
            }
        }
        milestones_comment
        milestones {
            url
            actual_date
            description
            planned_date
            status
            id
            status_report_id
        }
        initiative {
            issue_id
            initiative_type
            deputy_manager
            sponsor
            deputy_sponsor
        }
    }
`
export const ReleasedOrInReviewFragmentVertraulich = gql`
    fragment ReleasedOrInReviewDataVertraulich on status_report_status_report {
        actual_costs
        status_report_attachments {
            filename
            statusreport_id
        }
        planned_costs
        project_costs(order_by: {date: asc}) {
            amount
            date
            id
            type
            status_report_id
        }
        project_states {
            comment
        }
        risks_comment
        risks {
            url
            damage_extent
            description
            assignee_user {
                id
                full_name
            }
            estimated_cost
            id
            next_assessment
            probability_of_occurrence
            status
            status_report_id
            countermeasures {
                assignee_user {
                    id
                    full_name
                }
                description
                end_date
                id
                status
            }
        }
        problems_comment
        problems {
            url
            assignee
            due_date
            id
            name
            status_report_id
            status
            assignee_user {
                id
                full_name
            }
        }
        change_requests_comment
        change_requests {
            url
            id
            description
            status
            status_report_id
            assignee_user {
                id
                full_name
            }
            reporter_user {
                id
                full_name
            }
        }
    }
`

export const MilestoneFragment = gql`
    fragment MilestoneFragment on cockpit_be_milestone {
        actual_end_date
        status
        planned_end_date
        issue_id
        summary
        url
    }
`

export const RiskFragment = gql`
    fragment RiskFragment on cockpit_be_risk {
        damage_impact
        estimated_costs
        issue_id
        probability
        status
        summary
        url
        assignee_user {
            id
            full_name
        }
        next_assessment
        countermeasures {
            assignee_user {
                id
                full_name
            }
            summary
            end_date
            issue_id
            status
        }
    }
`

export const ProblemFragment = gql`
    fragment ProblemFragment on cockpit_be_problem {
        issue_id
        status
        summary
        url
        assignee_user {
            id
            full_name
        }
        end_date
    }
`

export const ChangeRequestFragment = gql`
    fragment ChangeRequestFragment on cockpit_be_change_request {
        issue_id
        summary
        status
        url
        assignee_user {
            id
            full_name
        }
        reporter_user {
            id
            full_name
        }
    }
`

export const UnreleasedOrRejectedFragment = gql`
    fragment UnreleasedOrRejectedData on status_report_status_report {
        id
        description
        next_milestones
        past_successes
        state
        report_date
        report_title
        milestones_comment
        risks_comment
        problems_comment
        change_requests_comment
        project_states {
            id
            state
            type
            status_report_id
            status_report {
                report_date
            }
            comment
        }
        status_report_attachments {
            filename
            statusreport_id
        }
        initiative {
            issue_id
            initiative_type
            status
            summary
            phase
            actual_end_date
            actual_start_date
            planned_end_date
            planned_start_date
            portfolio {
                name
            }
            manager_user {
                id
                full_name
            }
            deputy_manager
            sponsor
            deputy_sponsor
            milestones(where: {reportable: {_eq: true}}) {
                ...MilestoneFragment
            }
            risks(where: {reportable: {_eq: true}}) {
                ...RiskFragment
            }
            problems(where: {reportable: {_eq: true}}) {
                ...ProblemFragment
            }
            change_requests(where: {reportable: {_eq: true}}) {
                ...ChangeRequestFragment
            }
            children {
                summary
                milestones(where: {reportable: {_eq: true}}) {
                    ...MilestoneFragment
                }
                risks(where: {reportable: {_eq: true}}) {
                    ...RiskFragment
                }
                problems(where: {reportable: {_eq: true}}) {
                    ...ProblemFragment
                }
                change_requests(where: {reportable: {_eq: true}}) {
                    ...ChangeRequestFragment
                }
            }
        }
    }
    ${MilestoneFragment}
    ${RiskFragment}
    ${ProblemFragment}
    ${ChangeRequestFragment}
`
