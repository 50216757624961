import React from "react"
import styles from "./StatusOfProjects.module.scss"
import {StatusReportData} from "../statusReportData"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {gql, useQuery} from "@apollo/client"
import CircularProgress from "@mui/material/CircularProgress"
import {useTranslation} from "react-i18next"
import {formatDate, formatIsoDate} from "../../../../utils/dates"
import {StatusReportStateType, StatusReportStateValue} from "../statusReportData"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    GetProjectReportStatesForProgramReport,
    GetProjectReportStatesForProgramReportVariables,
} from "./types/GetProjectReportStatesForProgramReport"

const getProjectReportStatesForProgramReport = gql`
    query GetProjectReportStatesForProgramReport($program_report_id: uuid, $max_date: date) {
        cockpit_be_initiative(where: {parent: {status_reports: {id: {_eq: $program_report_id}}}}) {
            summary
            issue_id
            status_reports(
                limit: 1
                order_by: {report_date: desc}
                where: {state: {_in: ["released", "in_review"]}, report_date: {_lte: $max_date}}
            ) {
                report_date
                id
                project_states {
                    comment
                    state
                    type
                    status_report_id
                    id
                }
            }
        }
    }
`

interface Props {
    report: StatusReportData
}

export const StatusOfProjects: React.FC<Props> = ({report}) => {
    const {t} = useTranslation("translations")

    const {data, loading, error} = useQuery<GetProjectReportStatesForProgramReport, GetProjectReportStatesForProgramReportVariables>(
        getProjectReportStatesForProgramReport,
        {
            variables: {
                program_report_id: report.id,
                max_date: formatIsoDate(report.overview.report_date),
            },
        }
    )

    if (loading || !data) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-spinner">
                <CircularProgress />
            </div>
        )
    }
    if (error) throw Error(error.message)

    const projects = data.cockpit_be_initiative

    const types: StatusReportStateType[] = ["costs", "resources", "schedule", "scope", "total"]

    const statusIconPicker = (state: StatusReportStateValue) => {
        if (state === "in-order") return <FontAwesomeIcon icon="check-circle" style={{color: "#48c792"}} />
        if (state === "major-difficulties") return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337"}} />
        else return <FontAwesomeIcon icon="minus-circle" style={{color: "#dfd139"}} />
    }

    return (
        <div className={styles.tableContainer}>
            {projects.length !== 0 ? (
                <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                    <Table size="small" aria-label="milestones table" style={{tableLayout: "fixed"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={styles.headerTableCell}></TableCell>
                                {projects.map((project) => {
                                    return (
                                        <TableCell align="center" className={styles.headerTableCell} key={project.issue_id}>
                                            {project.summary}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {types.map((type, index) => {
                                const filteredByType = projects.flatMap((x) => {
                                    if (x.status_reports.length !== 0) {
                                        return x.status_reports[0].project_states.filter((y) => y.type === type)
                                    } else return null
                                })
                                return (
                                    <TableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell component="th" scope="row" sx={{fontWeight: "bold"}}>
                                            {t(type)}
                                        </TableCell>
                                        {filteredByType.map((state, index) => {
                                            if (state) {
                                                return (
                                                    <TableCell key={state?.id} align="center">
                                                        {statusIconPicker(state?.state as StatusReportStateValue)}
                                                    </TableCell>
                                                )
                                            } else
                                                return (
                                                    <TableCell key={index} align="center">
                                                        {"-"}
                                                    </TableCell>
                                                )
                                        })}
                                    </TableRow>
                                )
                            })}

                            <TableRow sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                <TableCell component="th" scope="row" sx={{fontWeight: "bold"}}>
                                    {t("report-date")}
                                </TableCell>
                                {projects.map((report, index) => {
                                    const reportDate = report.status_reports.length !== 0 ? report.status_reports[0].report_date : null
                                    return (
                                        <TableCell align="center" key={index}>
                                            {reportDate ? formatDate(new Date(reportDate)) : "-"}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </div>
    )
}
