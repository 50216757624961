import {
    FilterPortfolios_initiatives, FilterPortfolios_initiatives_status_reports,
} from "./types/FilterPortfolios"

export class PortfolioReportData {
    constructor(public readonly initiatives: FilterPortfolios_initiatives[]) {
    }

    public numberOfProjects(): number {
        return this.projects().length ?? 0
    }

    public numberOfPrograms(): number {
        return this.programs()?.length ?? 0
    }

    public projects(): FilterPortfolios_initiatives[] {
        return this.initiatives.filter(initiative => initiative.initiative_type === "project") ?? []
    }

    public programs(): FilterPortfolios_initiatives[] {
        return this.initiatives.filter(initiative => initiative.initiative_type === "program") ?? []
    }

    public reports(): (FilterPortfolios_initiatives_status_reports)[] {
        return this.initiatives.flatMap((initiative) => initiative.status_reports) ?? []
    }

    public filterProjects(field: string, value: string | null): FilterPortfolios_initiatives[] {
        return this.projects().filter((project) => {
            if (typeof project[field as keyof FilterPortfolios_initiatives] === "string") {
                return (project[field as keyof FilterPortfolios_initiatives] as string).toLowerCase() === value?.toLowerCase()
            } else {
                return (project[field as keyof FilterPortfolios_initiatives] as string) === value
            }
        })
    }

    public filterPrograms(field: string, value: string | null): FilterPortfolios_initiatives[] {
        return this.programs().filter((program) => {
            if (typeof program[field as keyof FilterPortfolios_initiatives] === "string") {
                return (program[field as keyof FilterPortfolios_initiatives] as string).toLowerCase() === value?.toLowerCase()
            } else {
                return (program[field as keyof FilterPortfolios_initiatives] as string) === value
            }
        })
    }

    public filterProjectsAndPrograms(field: string, value: string | null): (FilterPortfolios_initiatives)[] {
        return this.initiatives.filter((initiative) => {
            if (
                typeof initiative[field as keyof FilterPortfolios_initiatives] === "string" ||
                typeof initiative[field as keyof FilterPortfolios_initiatives] === "string"
            ) {
                return (initiative[field as keyof FilterPortfolios_initiatives] as string).toLowerCase() === value?.toLowerCase()
            } else {
                return (initiative[field as keyof FilterPortfolios_initiatives] as string) === value
            }
        })
    }
}
