import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, makeStyles, withStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {differenceInDays, subDays} from "date-fns"
import {StatusReportData, StatusReportMilestone} from "../statusReportData"
import {formatDate} from "../../../../utils/dates"
import {formatStringForTranslation} from "../../../../utils/strings"
import CommentTextCell from "./EditComponents/CommentTextCell"
import {gql, useMutation} from "@apollo/client"
import {UpdateMilestonesComment, UpdateMilestonesCommentVariables} from "./types/UpdateMilestonesComment"
import {useAuth} from "../../../../auth/AuthProvider"
import {ExternalLink} from "./ExternalLink"

const updateMilestonesComment = gql`
    mutation UpdateMilestonesComment($report_id: uuid!, $comment: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {milestones_comment: $comment}) {
            id
            milestones_comment
        }
    }
`

interface MilestonesTableProps {
    milestones: StatusReportMilestone[]
}

const useStyles = makeStyles(() =>
    createStyles({
        descTableCell: {
            width: "250px",
        },
        statusTableCell: {
            width: "80px",
        },
        endTableCell: {
            width: "180px",
        },
    }),
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: "#f8f8f8",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#fff",
            },
        },
    }),
)(TableRow)

const MilestonesTable: React.FC<MilestonesTableProps> = ({milestones}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()

    const statusIconPicker = (date: Date | string | null) => {
        if (!date) {
            return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337"}} />
        }
        const today = new Date()
        const fiveDaysBeforeDeadline = subDays(new Date(date), 5)
        const dateDeadline = new Date(date)
        if (today >= fiveDaysBeforeDeadline && today < dateDeadline)
            return <FontAwesomeIcon icon="minus-circle" style={{color: "#dfd139"}} />
        if (dateDeadline > today) return <FontAwesomeIcon icon="check-circle" style={{color: "#48c792"}} />
        if (dateDeadline < today) return <FontAwesomeIcon icon="exclamation-circle" style={{color: "#ed4337"}} />
    }

    return (
        <div className={styles.tableContainer}>
            {milestones.length !== 0 && (
                <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                    <Table size="small" aria-label="milestones table" style={{tableLayout: "fixed"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.descTableCell}>
                                    {t("short-description")}
                                </TableCell>
                                <TableCell align="left" className={classes.statusTableCell}>
                                    {t("status")}
                                </TableCell>
                                <TableCell align="right">{t("planned-date")}</TableCell>
                                <TableCell align="right">{t("actual-date")}</TableCell>
                                <TableCell align="right" className={classes.endTableCell}>
                                    {t("variance")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {milestones.sort(sortMilestones).map((milestone, index) => {
                                const variance = varianceInDays(milestone)
                                return (
                                    <StyledTableRow key={index} sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        <TableCell align="left" className={classes.descTableCell}>
                                            <span
                                                style={{marginRight: "10px"}}>{statusIconPicker(milestone.planned_date)}</span>
                                            <span className={styles.externalLink}>
                                                <ExternalLink name={milestone.description} url={milestone.url} />
                                                <sup>(2)</sup>
                                            </span>
                                        </TableCell>
                                        <TableCell align="left" className={classes.statusTableCell}>
                                            {t(`milestone-status-${formatStringForTranslation(milestone.status)}`)}
                                        </TableCell>
                                        <TableCell align="right">{formatDate(milestone.planned_date)}</TableCell>
                                        <TableCell align="right">{formatDate(milestone.actual_date)}</TableCell>
                                        <TableCell align="right" className={classes.endTableCell}>
                                            {variance ?? ""} {variance ? t("days") : ""}
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {milestones.length === 0 && (
                <div className={styles.noDataAvailable}>
                    <p>{t("no-data-available")}</p>
                </div>
            )}
        </div>
    )
}

function sortMilestones(left: StatusReportMilestone, right: StatusReportMilestone): number {
    let result = 0
    if ((left.planned_date || left.actual_date) && (right.planned_date || right.actual_date))
        result = (left.actual_date ?? left.planned_date)!!.getTime() - (right.actual_date ?? right.planned_date)!!.getTime()
    if (result === 0 && (left.planned_date || left.actual_date)) result = -1
    if (result === 0 && (right.actual_date || right.planned_date)) result = 1
    if (result === 0) result = left.description.localeCompare(right.description)
    return result
}

interface MilestonesProps {
    report: StatusReportData
    queryRefetch: any
}

export const Milestones: React.FC<MilestonesProps> = ({report, queryRefetch}) => {
    const auth = useAuth()
    const isReportEditable = report.isEditableBy(auth.current())

    const [update_milestones_comment] = useMutation<UpdateMilestonesComment, UpdateMilestonesCommentVariables>(updateMilestonesComment)
    const doUpdateComment = async (comment: string | null) => {
        await update_milestones_comment({
            variables: {
                report_id: report.id,
                comment: comment,
            },
        })
        queryRefetch()
    }

    const updateComment = async (value: string | null) => await doUpdateComment(value)
    const deleteComment = async () => await doUpdateComment(null)

    return (
        <>
            <CommentTextCell
                initialValue={report.milestones.comment}
                deleteComment={deleteComment}
                onChange={updateComment}
                editable={isReportEditable}
            />
            <MilestonesTable milestones={report.milestones.list} />
        </>
    )
}

function varianceInDays(milestone: StatusReportMilestone) {
    if (milestone.actual_date && milestone.planned_date) {
        return differenceInDays(milestone.actual_date, milestone.planned_date)
    }
}
