import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {SelectChangeEvent} from "@mui/material/Select"
import PageWrapper from "../../../components/PageWrapper"
import FilterDropdownsSection, {DropdownProps} from "../../../components/FilterDropdownsSection"
import ProjectListTable from "./ProjectListTable"
import {gql, useQuery} from "@apollo/client"
import {GetProjectsList} from "./types/GetProjectsList"
import {CircularProgress} from "@mui/material"
import {capitalizeFirstLetter, formatStringForTranslation} from "../../../utils/strings"
import {distinct, isPresent} from "../../../utils/arrays"
import {getPageMarginsDataGrid} from "../../../utils/printStyles"
import {useReactToPrint} from "react-to-print"
import {PortfolioAndOrgUnitProjectListFilter} from "./types/PortfolioAndOrgUnitProjectListFilter"
import {
    CategoryDropdown,
    CrossSectionSelectionDropdown,
    ExecutionTypeDropdown,
    KindDropdown,
    OrgUnitDropdown,
    PortfolioDropdown,
    StatusDropdown,
    ThemeTagsDropdown,
    TypeDropdown,
} from "../dropdowns"
import {CsvData} from "../../../components/ExportCSV"

const getPortfoliosAndOrgUnits = gql`
    query PortfolioAndOrgUnitProjectListFilter {
        projects: cockpit_be_initiative(distinct_on: organisation_unit) {
            organisation_unit
            portfolio {
                name
            }
        }
    }
`

const getProjectsList = gql`
    query GetProjectsList($where: cockpit_be_initiative_bool_exp) {
        projects: cockpit_be_initiative(where: $where, order_by: {summary: asc}) {
            id: issue_id
            manager
            type
            phase
            kind
            execution_type
            organisation_unit
            summary
            description
            category
            roadmap_relevance
            planned_end_date
            planned_start_date
            actual_end_date
            actual_start_date
            priority
            overview_url
            sponsor
            size
            status
            portfolio {
                name
            }
            cross_section_selections
            theme_tags
            stakeholders
        }
    }
`

const ProjectList = () => {
    const {t} = useTranslation("translations")
    const printStatusReportRef = React.useRef<HTMLDivElement | null>(null)

    const [portfolioOptions, setPortfolioOptions] = useState<{name: string; id: string}[]>([])
    const [orgUnitOptions, setOrgUnitOptions] = useState<{name: string; id: string}[]>([])

    const [overviewFilterOptions, setOverviewFilterOptions] = React.useState({
        portfolio: "",
        status: "",
        organisation_unit: "",
        kind: "",
        category: "",
        type: "",
        cross_section_selection: "",
        execution_type: "",
        theme_tags: "",
    })
    let filterVariables = {}
    Object.entries(overviewFilterOptions)
        .filter((x) => x[1] !== "")
        .forEach((x) => {
            if (x[0] === "portfolio") {
                filterVariables = {
                    ...filterVariables,
                    portfolio: {name: {_eq: x[1]}},
                }
            } else if (x[0] === "cross_section_selection") {
                filterVariables = {
                    ...filterVariables,
                    cross_section_selections: {_contains: x[1]},
                }
            } else if (x[0] === "theme_tags") {
                filterVariables = {
                    ...filterVariables,
                    theme_tags: {_contains: x[1]},
                }
            } else {
                filterVariables = {
                    ...filterVariables,
                    [x[0]]: {_eq: x[1]},
                }
            }
        })

    const handlePrint = useReactToPrint({
        content: () => printStatusReportRef.current,
        documentTitle: t("project-list"),
        //TODO: Space in print view may be caused by the content not loading quick enough before the react-to-print captures the contents of the datagrid:
        // onBeforePrint: () => {
        //     setTimeout(() => {
        //         console.log("delaying...")
        //     }, 100)
        // },
        // pageStyle: `@media print {
        //                        @page {
        //     zoom: 180%;
        //   }
        //                     }`,
    })

    const {
        data: filterData,
        error: orgUnitsError,
    } = useQuery<PortfolioAndOrgUnitProjectListFilter>(getPortfoliosAndOrgUnits)

    const {data, loading, error} = useQuery<GetProjectsList>(getProjectsList, {
        variables: {
            where: filterVariables,
        },
    })

    const createOptions = (array: string[], uppercase?: boolean) => [
        {name: `${t("all")}`, id: ""},
        ...array.map((x) => {
            if (uppercase) {
                return {name: capitalizeFirstLetter(x), id: x}
            }
            return {name: x, id: x}
        }),
    ]

    const createThemeTagOptions = (tags: string[]) => [
        {name: `${t("all")}`, id: ""},
        ...tags.map((tag) => {
            return {name: tag, id: tag}
        }),
    ]

    const projects = filterData?.projects ?? []

    const portfolios =
        projects
            .map((project) => project.portfolio?.name)
            .filter(isPresent)
            .filter(distinct) ?? []

    const themeTags =
        data?.projects
            .flatMap((project) => project.theme_tags)
            .filter(isPresent)
            .filter(distinct) ?? []

    const allOrgUnits =
        projects
            .flatMap((project) => project.organisation_unit)
            .filter(isPresent)
            .filter(distinct) ?? []

    const orgUnitsByPortfolio = new Map<string, string[]>()
    projects
        .filter((pro) => pro.portfolio && pro.organisation_unit)
        .forEach((project) => {
            const units = orgUnitsByPortfolio.get(project.portfolio?.name!)
            if (units === undefined) orgUnitsByPortfolio.set(project.portfolio?.name!, [project.organisation_unit!])
            else if (!units.includes(project.organisation_unit!)) units.push(project.organisation_unit!)
        })

    useEffect(() => {
        if (!filterData) return

        if (overviewFilterOptions.portfolio) {
            const selectedPortfolio = orgUnitsByPortfolio.get(overviewFilterOptions.portfolio)

            if (selectedPortfolio) {
                setOrgUnitOptions(createOptions(selectedPortfolio))
            }
        } else {
            setOrgUnitOptions(createOptions(allOrgUnits))
        }

        if (!overviewFilterOptions.organisation_unit) {
            setPortfolioOptions(createOptions(portfolios))
        } else {
            const ports: string[] = []
            orgUnitsByPortfolio.forEach((orgUnits, portfolio) => {
                if (orgUnits.includes(overviewFilterOptions.organisation_unit)) {
                    ports.push(portfolio)
                }
            })
            setPortfolioOptions(createOptions(ports))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterData, overviewFilterOptions])

    useEffect(() => {
        const selectedPortfolio = orgUnitsByPortfolio.get(overviewFilterOptions.portfolio)
        if (selectedPortfolio && !selectedPortfolio.includes(overviewFilterOptions.organisation_unit)) {
            setOverviewFilterOptions({
                ...overviewFilterOptions,
                organisation_unit: "",
            })
        }
    }, [overviewFilterOptions.portfolio])

    if (loading || !data || !filterData) {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} id="status-report-spinner">
                <CircularProgress />
            </div>
        )
    }
    if (error || orgUnitsError) throw Error(error?.message || orgUnitsError?.message)

    const dropdowns: DropdownProps[] = [
        PortfolioDropdown(portfolioOptions),
        OrgUnitDropdown(orgUnitOptions),
        StatusDropdown(t),
        CategoryDropdown(t),
        KindDropdown(t),
        TypeDropdown(t),
        CrossSectionSelectionDropdown(t),
        ExecutionTypeDropdown(t),
        ThemeTagsDropdown(() => createThemeTagOptions(themeTags)),
    ]

    const handleChange = (event: SelectChangeEvent) => {
        setOverviewFilterOptions({...overviewFilterOptions, [event.target.name]: event.target.value})
    }

    const getTranslatedValue = (key: string, value: any) => (value ? t(`${key}-${formatStringForTranslation(value)}`) : "")

    const csvData: CsvData<
        | "summary"
        | "phase"
        | "projectManager"
        | "portfolio"
        | "organization-unit"
        | "status"
        | "category"
        | "type"
        | "kind"
        | "cross-section-selection"
        | "execution-type"
        | "theme-tags"
    > = {
        header: {
            summary: t("summary"),
            phase: t("phase"),
            projectManager: t("project-manager"),
            portfolio: t("portfolio"),
            "organization-unit": t("organization-unit"),
            status: t("status"),
            category: t("category"),
            type: t("type"),
            kind: t("kind"),
            "cross-section-selection": t("cross-section-selection"),
            "execution-type": t("execution-type"),
            "theme-tags": t("theme-tags"),
        },
        data: data.projects.map((item) => ({
            summary: item.summary ?? "",
            phase: getTranslatedValue("phase", item.phase),
            projectManager: item.manager ?? "",
            portfolio: item.portfolio?.name ?? "",
            "organization-unit": item.organisation_unit ?? "",
            status: getTranslatedValue("status", item.status),
            category: getTranslatedValue("category", item.category),
            type: getTranslatedValue("type", item.type),
            kind: getTranslatedValue("kind", item.kind),
            "cross-section-selection": item.cross_section_selections.map((value: string) => t(`cross-section-${value}`)).join(", "),
            "execution-type": getTranslatedValue("execution-type", item.execution_type),
            "theme-tags": item.theme_tags.join(", ") ?? "",
        })),
    }

    return (
        <>
            <style>{getPageMarginsDataGrid()}</style>
            <div ref={printStatusReportRef}>
                <PageWrapper headerTitle={t("project-list")} icon={"list" as IconProp}>
                    <FilterDropdownsSection
                        handleChange={handleChange}
                        filterValues={overviewFilterOptions}
                        dropdowns={dropdowns}
                        handlePrint={handlePrint}
                        csvExportData={csvData}
                    />

                    <ProjectListTable projects={data.projects} />
                </PageWrapper>
            </div>
        </>
    )
}

export default ProjectList
