import {max} from "date-fns"
import {
    GetReport,
    GetReport_releasedOrInReview_report,
    GetReport_releasedOrInReview_report_project_states_status_report_status_report,
    GetReport_releasedOrInReview_report_status_report_attachments,
    GetReport_unreleasedOrRejected_report,
    GetReport_unreleasedOrRejected_report_status_report_attachments,
} from "./types/GetReport"
import {AuthUser} from "../../../auth/auth"
import {RiskFragment} from "./types/RiskFragment"
import {MilestoneFragment} from "./types/MilestoneFragment"
import {ProblemFragment} from "./types/ProblemFragment"
import {ChangeRequestFragment} from "./types/ChangeRequestFragment"

export class StatusReportData {
    constructor(
        public readonly id: string,
        public readonly type: "program" | "project",
        public readonly overview: StatusReportOverview,
        public readonly attachments: StatusReportAttachments,
        public readonly summary: StatusReportSummary,
        public readonly states: StatusReportState[],
        public readonly milestones: StatusReportMilestones,
        public readonly risks: StatusReportRisks,
        public readonly problems: StatusReportProblems,
        public readonly changeRequests: StatusReportChangeRequests,
    ) {
    }

    currentState(): StatusReportState {
        return this.states.filter((state) => state.report_id === this.id)[0]
    }

    isEditableBy(user: AuthUser | null): boolean {
        if (user == null) return false

        if (this.overview.state === "unreleased") return this.isManager(user)
        if (this.overview.state === "rejected") return this.isManager(user)
        if (this.overview.state === "in_review") return this.isSponsor(user)
        return false
    }

    isManager(user: AuthUser): boolean {
        return this.overview.manager?.id === user.email || this.overview.deputy_manager_id === user.email
    }

    isSponsor(user: AuthUser): boolean {
        return this.overview.sponsor_id === user.email || this.overview.deputy_sponsor_id === user.email
    }
}

export interface StatusReportOverview {
    project_name: string
    manager: User | null
    deputy_manager_id: string | null
    sponsor_id: string | null
    deputy_sponsor_id: string | null
    project_status: string | null
    portfolio: string
    state: string
    phase: string | null
    planned_start_date: Date | null
    planned_end_date: Date | null
    planned_costs: number | null
    actual_start_date: Date | null
    actual_end_date: Date | null
    actual_costs: number | null
    report_date: Date
    report_title: string | null
    completion_date: Date | null
    release_date: Date | null
}

export interface User {
    id: string
    full_name: string
}

export interface StatusReportSummary {
    description: string | null
    past_successes: string | null
    next_milestones: string | null
}

export interface StatusReportAttachments {
    attachments:
        | GetReport_releasedOrInReview_report_status_report_attachments[]
        | GetReport_unreleasedOrRejected_report_status_report_attachments[]
}

export type StatusReportStateType = "costs" | "resources" | "schedule" | "scope" | "total"
export type StatusReportStateValue = "in-order" | "major-difficulties" | "minor-difficulties"

export interface StatusReportState {
    id: string
    state: StatusReportStateValue
    type: StatusReportStateType
    comment: string | null
    date: Date
    report_id: string
}

export interface StatusReportMilestones {
    comment: string | null
    list: StatusReportMilestone[]
}

export interface StatusReportMilestone {
    id: string
    description: string
    status: string
    planned_date: Date | null
    actual_date: Date | null
    url: string | null
}

export type StatusReportRiskStatus = "Open" | "In Progress" | "Eingetreten" | "Nicht eingetreten"

export interface StatusReportRisks {
    comment: string | null
    list: StatusReportRisk[]
}

export interface StatusReportRisk {
    id: string
    status: StatusReportRiskStatus
    description: string
    probability_of_occurrence: string | null
    damage_extent: string | null
    estimated_cost: number | null
    assignee: User | null
    next_assessment: Date | null
    countermeasures: StatusReportRiskCountermeasure[]
    url: string | null
}

export interface StatusReportRiskCountermeasure {
    id: string
    status: string
    description: string
    assignee: User | null
    end_date: Date | null
}

export interface StatusReportProblems {
    comment: string | null
    list: StatusReportProblem[]
}

export interface StatusReportProblem {
    id: string
    name: string
    assignee: User | null
    status: string
    due_date: Date | null
    url: string | null
}

export interface StatusReportChangeRequests {
    comment: string | null
    list: StatusReportChangeRequest[]
}

export interface StatusReportChangeRequest {
    description: string
    reporter: User | null
    assignee: User | null
    status: string
    url: string | null
}

const toDate = (date: string | null): Date | null => {
    if (date) {
        return new Date(date)
    }
    return null
}

export const unreleasedOrRejectedReport = (report: GetReport): GetReport_unreleasedOrRejected_report | undefined => {
    if (report.unreleasedOrRejected_report?.length > 0) {
        return report.unreleasedOrRejected_report[0]
    }
}

const inReviewOrReleasedReport = (report: GetReport): GetReport_releasedOrInReview_report | undefined => {
    if (report.releasedOrInReview_report?.length > 0) {
        return report.releasedOrInReview_report[0]
    }
}

const getReportByState = (report: GetReport): GetReport_unreleasedOrRejected_report | GetReport_releasedOrInReview_report => {
    if (unreleasedOrRejectedReport(report)) {
        return unreleasedOrRejectedReport(report)!
    }
    return inReviewOrReleasedReport(report)!
}

const overview = (report: GetReport): StatusReportOverview => {
    if (inReviewOrReleasedReport(report)) {
        const inReviewOrReleased = inReviewOrReleasedReport(report)!
        const initiative = inReviewOrReleased.initiative

        return {
            project_name: inReviewOrReleased.project_name,
            manager: toUser(inReviewOrReleased.project_manager_user),
            deputy_manager_id: initiative?.deputy_manager ?? null,
            sponsor_id: initiative?.sponsor ?? null,
            deputy_sponsor_id: initiative?.deputy_sponsor ?? null,
            project_status: inReviewOrReleased.project_status,
            portfolio: inReviewOrReleased.portfolio,
            state: inReviewOrReleased.state,
            phase: inReviewOrReleased.project_phase,
            planned_start_date: toDate(inReviewOrReleased.planned_start_date),
            planned_end_date: toDate(inReviewOrReleased.planned_end_date),
            planned_costs: inReviewOrReleased.planned_costs,
            actual_start_date: toDate(inReviewOrReleased.actual_start_date),
            actual_end_date: toDate(inReviewOrReleased.actual_end_date),
            actual_costs: inReviewOrReleased.actual_costs,
            report_date: toDate(inReviewOrReleased.report_date)!,
            report_title: inReviewOrReleased.report_title,
            completion_date: latestStateChangeTo("in_review", report),
            release_date: latestStateChangeTo("released", report),
        }
    }

    const unreleased = unreleasedOrRejectedReport(report)!
    const initiative = unreleased.initiative

    return {
        project_name: initiative?.summary!,
        manager: toUser(initiative?.manager_user ?? null),
        deputy_manager_id: initiative?.deputy_manager ?? null,
        sponsor_id: initiative?.sponsor ?? null,
        deputy_sponsor_id: initiative?.deputy_sponsor ?? null,
        project_status: initiative?.status!,
        portfolio: initiative?.portfolio?.name!,
        state: unreleased.state,
        phase: initiative?.phase ?? null,
        planned_start_date: toDate(initiative?.planned_start_date ?? null),
        planned_end_date: toDate(initiative?.planned_end_date ?? null),
        planned_costs: report.costs?.total_planned ?? null,
        actual_start_date: toDate(initiative?.actual_start_date ?? null),
        actual_end_date: toDate(initiative?.actual_end_date ?? null),
        actual_costs: report.costs?.total_actual ?? null,
        report_date: new Date(unreleased.report_date ?? ""),
        report_title: unreleased.report_title,
        completion_date: new Date(),
        release_date: null,
    }
}

const toUser = (user: {id: string | null, full_name: string | null} | null): User | null => {
    if (user?.id && user?.full_name) return {
        id: user.id,
        full_name: user.full_name,
    }
    return null
}

const latestStateChangeTo = (state: string, report: GetReport) => {
    const dates = report.history.filter((history) => history.new_state === state).map((state) => new Date(state.timestamp))
    if (dates.length === 0) return null
    return max(dates)
}

const attachments = (report: GetReport): StatusReportAttachments => {
    return {attachments: getReportByState(report).status_report_attachments}
}

const summary = (report: GetReport): StatusReportSummary => {
    return getReportByState(report) as StatusReportSummary
}

const states = (getReport: GetReport): StatusReportState[] => {
    const report = getReportByState(getReport)
    const currentStates = report.project_states.map((state) => {
        return {
            id: state.id,
            state: state.state as StatusReportStateValue,
            type: state.type as StatusReportStateType,
            comment: (state as GetReport_releasedOrInReview_report_project_states_status_report_status_report).comment,
            date: new Date(state.status_report.report_date),
            report_id: state.status_report_id,
        }
    })

    const oldStates =
        getReport.status_history?.initiative?.status_reports
            .flatMap((oldReport) =>
                oldReport.project_states.map((state) => {
                    return {
                        id: state.id,
                        state: state.state as StatusReportStateValue,
                        type: state.type as StatusReportStateType,
                        comment: null,
                        date: toDate(oldReport.report_date)!,
                        report_id: state.id,
                    }
                }),
            )
            .reverse() || []

    return [...oldStates, ...currentStates]
}

const milestones = (report: GetReport): StatusReportMilestones => {
    if (inReviewOrReleasedReport(report)) {
        const milestones = inReviewOrReleasedReport(report)?.milestones ?? []

        return {
            comment: inReviewOrReleasedReport(report)?.milestones_comment ?? null,
            list: milestones.map((milestone) => ({
                id: milestone.id,
                description: milestone.description,
                status: milestone.status,
                planned_date: toDate(milestone.planned_date),
                actual_date: toDate(milestone.actual_date),
                url: milestone.url,
            })),
        }
    }

    const toMilestone = (fragment: MilestoneFragment) => ({
        id: fragment.issue_id,
        description: fragment.summary,
        status: fragment.status,
        planned_date: toDate(fragment.planned_end_date),
        actual_date: toDate(fragment.actual_end_date),
        url: fragment.url,
    })
    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const milestones = initiative?.milestones.map((milestone) => toMilestone(milestone)) ?? []

    const childrenMilestones =
        initiative?.children?.flatMap((child) => child.milestones.flatMap((milestone) => toMilestone(milestone))) ?? []

    return {
        comment: unreleasedOrRejectedReport(report)?.milestones_comment ?? null,
        list: [...milestones, ...childrenMilestones],
    }
}

const risks = (report: GetReport): StatusReportRisks => {
    if (inReviewOrReleasedReport(report)) {
        const risks = inReviewOrReleasedReport(report)?.risks ?? []

        return {
            comment: inReviewOrReleasedReport(report)?.risks_comment ?? null,
            list: risks.map((risk) => (
                {
                    id: risk.id,
                    status: risk.status as StatusReportRiskStatus,
                    description: risk.description,
                    probability_of_occurrence: risk.probability_of_occurrence,
                    damage_extent: risk.damage_extent,
                    estimated_cost: risk.estimated_cost,
                    assignee: toUser(risk.assignee_user),
                    next_assessment: toDate(risk.next_assessment),
                    url: risk.url,
                    countermeasures: risk.countermeasures.map((measure) => (
                        {
                            id: measure.id,
                            status: measure.status,
                            description: measure.description,
                            assignee: toUser(measure.assignee_user),
                            end_date: toDate(measure.end_date),
                        }),
                    ),
                }),
            ),
        }
    }

    const toRisk = (fragment: RiskFragment) => ({
        id: fragment.issue_id,
        status: fragment.status as StatusReportRiskStatus,
        description: fragment.summary,
        probability_of_occurrence: fragment.probability,
        damage_extent: fragment.damage_impact,
        estimated_cost: fragment.estimated_costs,
        assignee: toUser(fragment.assignee_user),
        url: fragment.url,
        next_assessment: toDate(fragment.next_assessment),
        countermeasures: fragment.countermeasures.map((measure) => ({
                id: measure.issue_id,
                status: measure.status,
                description: measure.summary,
                assignee: toUser(measure.assignee_user),
                end_date: toDate(measure.end_date),
            }),
        ),
    })

    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const risks = initiative?.risks.map((risk) => toRisk(risk)) ?? []

    const childrenRisks =
        initiative?.children.flatMap((child) => {
            return child.risks.flatMap((risk) => toRisk(risk))
        }) ?? []

    return {
        comment: unreleasedOrRejectedReport(report)?.risks_comment ?? null,
        list: [...risks, ...childrenRisks],
    }
}

const problems = (report: GetReport): StatusReportProblems => {
    if (inReviewOrReleasedReport(report)) {
        const problems = inReviewOrReleasedReport(report)?.problems ?? []
        return {
            comment: inReviewOrReleasedReport(report)?.problems_comment ?? null,
            list: problems.map((problem) => ({
                id: problem.id,
                name: problem.name,
                assignee: toUser(problem.assignee_user),
                status: problem.status,
                due_date: toDate(problem.due_date),
                url: problem.url,
            })),
        }
    }

    const toProblem = (fragment: ProblemFragment): StatusReportProblem => ({
        id: fragment.issue_id,
        name: fragment.summary,
        assignee: toUser(fragment.assignee_user),
        status: fragment.status,
        due_date: toDate(fragment.end_date),
        url: fragment.url,
    })

    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const problems = initiative?.problems.map((problem) => toProblem(problem)) ?? []
    const childrenProblems =
        initiative?.children.flatMap((child) => {
            return child.problems.flatMap((problem) => toProblem(problem))
        }) ?? []

    return {
        comment: unreleasedOrRejectedReport(report)?.problems_comment ?? null,
        list: [...problems, ...childrenProblems],
    }
}

export const changeRequests = (report: GetReport): StatusReportChangeRequests => {
    if (inReviewOrReleasedReport(report)) {
        const change_requests = inReviewOrReleasedReport(report)?.change_requests ?? []
        return {
            comment: inReviewOrReleasedReport(report)?.change_requests_comment ?? null,
            list: change_requests.map((changeRequest) => ({
                description: changeRequest.description,
                status: changeRequest.status,
                assignee: toUser(changeRequest.assignee_user),
                reporter: toUser(changeRequest.reporter_user),
                url: changeRequest.url,
            })),
        }
    }

    const toChangeRequest = (fragment: ChangeRequestFragment): StatusReportChangeRequest => ({
        description: fragment.summary,
        status: fragment.status,
        assignee: toUser(fragment.assignee_user),
        reporter: toUser(fragment.reporter_user),
        url: fragment.url,
})

    const initiative = unreleasedOrRejectedReport(report)?.initiative
    const changeRequests = initiative?.change_requests.map((changeRequest) => toChangeRequest(changeRequest)) ?? []
    const childrenChangeRequests =
        initiative?.children.flatMap((child) => {
            return child.change_requests.flatMap((changeRequest) => toChangeRequest(changeRequest))
        }) ?? []

    return {
        comment: unreleasedOrRejectedReport(report)?.change_requests_comment ?? null,
        list: [...changeRequests, ...childrenChangeRequests],
    }
}

const reportType = (report: GetReport): "program" | "project" => {
    if (inReviewOrReleasedReport(report)) {
        const inReviewOrReleased = inReviewOrReleasedReport(report)!
        return inReviewOrReleased.initiative?.initiative_type ? "project" : "program"
    }
    const unreleased = unreleasedOrRejectedReport(report)!
    return unreleased.initiative?.initiative_type ? "project" : "program"
}

export const toStatusReportData = (report: GetReport): StatusReportData => {
    const reportOverview = overview(report)
    const reportAttachments = attachments(report)
    const reportSummary = summary(report)
    const reportStates = states(report)
    const reportMilestones = milestones(report)
    const reportRisks = risks(report)
    const reportProblems = problems(report)
    const reportChangeRequests = changeRequests(report)
    const type = reportType(report)

    return new StatusReportData(
        getReportByState(report).id,
        type,
        reportOverview,
        reportAttachments,
        reportSummary,
        reportStates,
        reportMilestones,
        reportRisks,
        reportProblems,
        reportChangeRequests,
    )
}
