import React from "react"
import {gql, useQuery} from "@apollo/client"
import {useAuth} from "./AuthProvider"
import {User} from "./user"
import {GetReportPermissions, GetReportPermissionsVariables} from "./types/GetReportPermissions";

export const UserContext = React.createContext<User | null>(null)

export function useUser(): User {
    const user = useUserIfAvailable()
    if (!user) throw new Error("No user is logged in")
    return user
}

export function useUserIfAvailable(): User | null {
    return React.useContext(UserContext)
}

const getReportPermissions = gql`
    query GetReportPermissions($user_id: String!) {
        permissions: status_report_permission(where: {user_id: {_eq: $user_id}}) {
            user_id
            report_id
            manager
            sponsor
            statusbericht_vollberechtigte
        }
    }
`

interface Props {
}

export const UserProvider: React.FC<Props> = ({children}) => {
    const userId = useAuth().current()?.email

    const {data} = useQuery<GetReportPermissions, GetReportPermissionsVariables>(getReportPermissions, {
        variables: {user_id: userId!}, skip: !userId
    })

    const permissions = data?.permissions
    const isAllowedToSeeFullStatusReport = (statusReportId: string) => {
        return permissions?.filter((report) => report.report_id === statusReportId)
            .map((report) => {
                return (report.manager ?? false) || (report.sponsor ?? false) || (report.statusbericht_vollberechtigte ?? false)
            }).reduce((prev, current) => prev || current, false) ?? false
    }
    console.log(`permissions for ${userId}`, {permissions})
    const user: User = {
        isAllowedToSeeFullStatusReport(statusReportId: string): boolean {
            return isAllowedToSeeFullStatusReport(statusReportId)
        },
    }

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
