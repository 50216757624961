import React from "react"
import styles from "./FilterDropdownsSection.module.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {SelectChangeEvent} from "@mui/material/Select"
import SelectDropdown from "./SelectDropdown"
import IconButton from "@mui/material/IconButton"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import Grid from "@mui/material/Grid"
import {useTranslation} from "react-i18next"
import {CsvData, ExportCSV} from "./ExportCSV"

export interface DropdownProps {
    title: string
    options: {name: string; id: string}[]
    icon: string
    type: string
}

interface Props {
    handleChange: (event: SelectChangeEvent) => void
    filterValues: {[key: string]: string}
    dropdowns: DropdownProps[]
    handlePrint?: any
    csvExportData?: CsvData<any>
}

const FilterDropdownsSection: React.FC<Props> = ({handleChange, filterValues, dropdowns, handlePrint, csvExportData}) => {
    const {t} = useTranslation("translations")

    return (
        <div className={styles.dropdownSection}>
            <div className={styles.selectsWrapper}>
                <Grid container spacing={1}>
                    {dropdowns.map((dropdown, index) => {
                        let translationTitle = dropdown.title.split(" ").join("-") //Removes spaces from string and replaces with "-"
                        translationTitle = translationTitle.split("_").join("-") //Removes underscores "_" from string and replaces with "-"

                        return (
                            <Grid item key={index}>
                                <SelectDropdown
                                    options={dropdown.options}
                                    handleChange={handleChange}
                                    value={filterValues[dropdown.type]}
                                    title={t(translationTitle)}
                                    type={dropdown.type}
                                    icon={dropdown.icon}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
            <div className={styles.iconsContainer}>
                <IconButton onClick={handlePrint} className={styles.actionIcon}>
                    <FontAwesomeIcon icon={["fas", "print"] as IconProp} size="xs" />
                </IconButton>
                {csvExportData && <ExportCSV data={csvExportData} />}
            </div>
        </div>
    )
}

export default FilterDropdownsSection
