import styles from "../StatusReport.module.scss"

interface Props {
    url: string | null
    name: string
}

export const ExternalLink: React.FC<Props> = ({url, name}) => {
    return (
        <>
            {url ? (
                <span className={styles.externalLink}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {name}
                    </a>
                </span>
            ) : (
                <span>{name}</span>
            )}
        </>
    )
}
