import React from "react"
import styles from "./ProjectList.module.scss"
import Box from "@mui/material/Box"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import Backdrop from "@mui/material/Backdrop"
import {GetProjectsList_projects} from "./types/GetProjectsList"
import {IconButton} from "@mui/material"
import {openExternalProject} from "../../../utils/link"
import {formatStringForTranslation} from "../../../utils/strings"
import {determineDuration} from "../utils/duration"
import {Trans, useTranslation} from "react-i18next"

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "63%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    "&:focus-visible": {
        outline: "none",
    },
}

interface Props {
    open: boolean
    handleClose: () => void
    projectData: GetProjectsList_projects | null
}

const Steckbrief: React.FC<Props> = ({open, handleClose, projectData}) => {
    const {t} = useTranslation("translations")

    const determinePriorityColor = (priority: string | null) => {
        if (priority === "High") return "#fd5631"
        else if (priority === "Medium") return "#FAAD00"
        else if (priority === "Low") return "#4c9aff"
        else return "none"
    }

    const translate = (prefix: string, value: string | undefined | null) => {
        if (!value) return "-"
        return t(formatStringForTranslation(`${prefix}-${value?.replace(" ", "-")}`))
    }

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    {projectData && (
                        <>
                            <div className={styles.modalTitleWrapper}>
                                <div className={styles.modalTitle}>
                                    <FontAwesomeIcon icon={["far", "thumbtack"]} />
                                    <h3>{projectData.summary}</h3>
                                </div>

                                {projectData.overview_url && (
                                    <IconButton onClick={() => openExternalProject(projectData.overview_url)}>
                                        <FontAwesomeIcon icon="external-link-alt" size="xs" />
                                    </IconButton>
                                )}
                            </div>
                            <div style={{background: "#f8f8f8", padding: "10px 20px", borderRadius: "10px"}}>
                                <div className={styles.profileInfoWrapper}>
                                    <div className={styles.column}>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("summary")}</p>
                                            <p className={styles.value}>{projectData.summary}</p>
                                        </div>
                                    </div>
                                    <div className={styles.column}>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("priority")}</p>
                                            <div
                                                className={styles.priorityChip}
                                                style={{
                                                    backgroundColor: determinePriorityColor(projectData.priority),
                                                }}
                                            >
                                                {/* //TODO: Translations for priority once values are confirmed */}
                                                <span>{projectData.priority}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.shortDescWrapper}
                                        style={{width: "100%", borderBottom: "1px solid rgb(230 230 230)"}}
                                    >
                                        <p className={styles.valueTitle}>{t("short-description")}</p>
                                        <p className={styles.value}>{projectData.description || "-"}</p>
                                    </div>

                                    <div className={styles.column} style={{marginTop: "10px"}}>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("status")}</p>
                                            <p className={styles.value}>{projectData.status}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("project-phase")}</p>
                                            <p className={styles.value}>{translate("phase", projectData.phase) || "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("client")}</p>
                                            <p className={styles.value}>{projectData.sponsor || "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("project-manager")}</p>
                                            <p className={styles.value}>{projectData.manager || "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("organizational-unit")}</p>
                                            <p className={styles.value}>{projectData.organisation_unit || "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("project-type")}</p>
                                            <p className={styles.value}>{translate("type", projectData.type) || "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("project-category")}</p>
                                            <p className={styles.value}>{translate("category", projectData.category) ?? "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("cross-section-selection")}</p>
                                            <p className={styles.value}>
                                                {projectData.cross_section_selections
                                                    .map((selection: string) => translate("cross-section", selection) ?? "")
                                                    .join(", ") || "-"}
                                            </p>
                                        </div>
                                    </div>

                                    <div className={styles.column} style={{marginTop: "10px"}}>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("kind")}</p>
                                            <p className={styles.value}>{projectData.kind ?? "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("project-size")}</p>
                                            <p className={styles.value}>{projectData.size ?? "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("project-kind")}</p>
                                            <p className={styles.value}>
                                                {projectData.kind ? t(`kind-${projectData.kind.toLowerCase()}`) : "-"}
                                            </p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("roadmap-relevance")}</p>
                                            <p className={styles.value}>{projectData.roadmap_relevance ?? "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("stakeholders")}</p>
                                            <p className={styles.value}>
                                                {projectData.stakeholders
                                                    .map((stakeholder: string) => translate("stakeholder", stakeholder) ?? "")
                                                    .join(", ") || "-"}
                                            </p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("duration")}</p>
                                            <p className={styles.value}>{determineDuration(projectData)}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("execution-type")}</p>
                                            <p className={styles.value}>{projectData.execution_type ?? "-"}</p>
                                        </div>
                                        <div className={styles.valueWrapper}>
                                            <p className={styles.valueTitle}>{t("theme-tags")}</p>
                                            <div className={styles.themeTagsWrapper}>
                                                {projectData.theme_tags.length > 0
                                                    ? projectData.theme_tags.map((tag: string) => {
                                                          return (
                                                              <div className={styles.themeChip}>
                                                                  <span>{tag}</span>
                                                              </div>
                                                          )
                                                      })
                                                    : "-"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.footerDiv}>
                                <div className={styles.descriptionText}>
                                    <Trans i18nKey="link-to-field-descriptions-helpertext">
                                        Infos zu diesen Feldern finden Sie
                                        <a target="_blank" rel="noreferrer" href="https://projekte.be.ch/x/Log7AQ">
                                            hier
                                        </a>
                                        .
                                    </Trans>
                                </div>
                                <div style={{textAlign: "right", marginTop: "20px"}}>
                                    <Button variant="contained" onClick={() => handleClose()}>
                                        {t("close")}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                </Box>
            </Fade>
        </Modal>
    )
}

export default Steckbrief
