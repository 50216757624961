import React from "react"
import styles from "../StatusReport.module.scss"
import {createStyles, makeStyles, withStyles} from "@mui/styles"
import {useTranslation} from "react-i18next"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import Text from "react-texty"
import "react-texty/styles.css"
import {StatusReportData, StatusReportRisk, StatusReportRiskStatus} from "../statusReportData"
import Collapse from "@mui/material/Collapse"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred"
import IconButton from "@mui/material/IconButton"
import {formatDate} from "../../../../utils/dates"
import {formatAmount} from "../../../../utils/numbers"
import {formatStringForTranslation} from "../../../../utils/strings"
import Tooltip from "@mui/material/Tooltip"
import CommentTextCell from "./EditComponents/CommentTextCell"
import {useAuth} from "../../../../auth/AuthProvider"
import {gql, useMutation} from "@apollo/client"
import {UpdateRisksComment, UpdateRisksCommentVariables} from "./types/UpdateRisksComment"
import {ExternalLink} from "./ExternalLink"

const updateRisksComment = gql`
    mutation UpdateRisksComment($report_id: uuid!, $comment: String) {
        update_status_report_status_report_by_pk(pk_columns: {id: $report_id}, _set: {risks_comment: $comment}) {
            id
            risks_comment
        }
    }
`

interface Props {
    risks: StatusReportRisk[]
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

interface RowProps {
    risk: StatusReportRisk
    index: number
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

const useStyles = makeStyles(() =>
    createStyles({
        outerTable: {
            "& tr:last-child td": {
                borderBottom: 0,
            },
        },
        descTableCell: {
            width: "250px",
        },
        editorCell: {
            width: "180px",
        },
        statusTableCell: {
            width: "80px",
        },
        truncatedText: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },

        innerTableBody: {
            "& tr:last-of-type": {
                borderBotom: 0,
                "& td:nth-child(2)": {
                    borderRadius: "0px 0px 0px 5px",
                },
                "& td:last-child": {
                    borderRadius: "0px 0px 5px 0px",
                },
            },
            "& tr td": {
                backgroundColor: "#fff",
            },
        },
        innerTableHeader: {
            "& th:nth-child(2)": {
                borderRadius: "5px 0px 0px 0px",
            },
            "& th:last-child": {
                borderRadius: "0px 5px 0px 0px",
            },
            "& th": {
                backgroundColor: "#f8f8f8",
            },
        },
    })
)

const StyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "& .MuiTableCell-head span": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    })
)(TableRow)

const InnerStyledTableRow = withStyles(() =>
    createStyles({
        root: {
            "& th": {
                color: "#2a2a2a",
                fontSize: "0.8rem",
            },
            "& td": {
                fontSize: "0.8rem",
            },

            "&:last-child td, &:last-child th": {
                border: 0,
            },
            "& .MuiTableCell-head span": {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    })
)(TableRow)

const RisksSection: React.FC<Props> = ({risks}) => {
    const {t} = useTranslation("translations")
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const getRisksByStatus = (status: StatusReportRiskStatus[]) => {
        return risks.filter((risk) => status.indexOf(risk.status as StatusReportRiskStatus) >= 0)
    }

    const openRisks = getRisksByStatus(["Open", "In Progress"])
    const closedRisks = getRisksByStatus(["Eingetreten", "Nicht eingetreten"])

    const hasCountermeasures = (risk: StatusReportRisk) => risk.countermeasures.length > 0

    const Row: React.FC<RowProps> = ({risk, index}) => {
        const [open, setOpen] = React.useState(false)

        return (
            <React.Fragment key={index}>
                <StyledTableRow>
                    <TableCell>
                        {hasCountermeasures(risk) ? (
                            <IconButton aria-label="expand row" size="small" sx={{padding: "0px"}} onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        ) : (
                            <Tooltip title={t("no-countermeasures-tooltip") || ""}>
                                <ReportGmailerrorredIcon fontSize="small" sx={{color: "rgba(0, 0, 0, 0.54)", marginLeft: "3px"}} />
                            </Tooltip>
                        )}
                    </TableCell>
                    <TableCell align="left" className={classes.descTableCell}>
                        <span className={styles.externalLink}>
                            <ExternalLink name={risk.description} url={risk.url} />
                            <sup>(2)</sup>
                        </span>
                    </TableCell>
                    <TableCell align="left" className={classes.statusTableCell}>
                        {t(`risk-status-${formatStringForTranslation(risk.status)}`)}
                    </TableCell>
                    <TableCell align="right">{risk.probability_of_occurrence}</TableCell>
                    <TableCell align="right">{risk.damage_extent}</TableCell>
                    <TableCell align="right">{formatAmount(risk.estimated_cost)}</TableCell>
                    <TableCell align="right">{formatDate(risk.next_assessment)}</TableCell>
                    <TableCell align="right" className={classes.editorCell}>
                        {risk.assignee?.full_name || ""}
                    </TableCell>
                </StyledTableRow>
                {hasCountermeasures(risk) && (
                    <InnerStyledTableRow style={open ? {backgroundColor: "rgb(237, 237, 237)"} : {backgroundColor: "white"}}>
                        <TableCell style={open ? {padding: "10px"} : {padding: "0px", border: 0}} colSpan={100}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box>
                                    <Table size="small" aria-label="purchases" sx={{tableLayout: "fixed"}}>
                                        <TableHead>
                                            <TableRow className={classes.innerTableHeader}>
                                                <TableCell sx={{width: "23%"}}>{t("counter-measure")}</TableCell>
                                                <TableCell>{t("status")}</TableCell>
                                                <TableCell align="right">{t("end_date")}</TableCell>
                                                <TableCell align="right">{t("assignee")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={classes.innerTableBody}>
                                            {risk.countermeasures.map((measure) => {
                                                return (
                                                    <TableRow key={measure.id}>
                                                        <TableCell sx={{width: "23%"}}>{measure.description}</TableCell>
                                                        <TableCell>
                                                            {t(`risk-countermeasure-status-${formatStringForTranslation(measure.status)}`)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {measure.end_date && formatDate(measure.end_date)}
                                                        </TableCell>
                                                        <TableCell align="right">{measure.assignee?.full_name}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </InnerStyledTableRow>
                )}
            </React.Fragment>
        )
    }

    const RisksTable: React.FC<{risks: StatusReportRisk[]}> = ({risks}) => {
        return (
            <div className={styles.tableContainer}>
                {risks.length !== 0 && (
                    <TableContainer component={Paper} sx={{boxShadow: "unset"}}>
                        <Table
                            size="small"
                            aria-label="simple table"
                            //Reactivating the below style will alter the table columns and the longer text will be truncated again:
                            // style={{tableLayout: "fixed"}}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" className={classes.descTableCell} colSpan={2}>
                                        {t("short-description")}
                                    </TableCell>
                                    <TableCell align="left" className={classes.statusTableCell}>
                                        {t("status")}
                                    </TableCell>
                                    <TableCell align="right" className={classes.truncatedText}>
                                        <Text tooltip={t("probability-of-occurrence")}>{t("probability-of-occurrence")}</Text>
                                    </TableCell>
                                    <TableCell align="right" className={classes.truncatedText}>
                                        <Text tooltip={t("damage-extent")}>{t("damage-extent")}</Text>
                                    </TableCell>
                                    <TableCell align="right">{t("estimated-cost")}</TableCell>
                                    <TableCell align="right">{t("next-assessment")}</TableCell>
                                    <TableCell align="right" className={classes.editorCell}>
                                        {t("assignee")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.outerTable}>
                                {risks.sort(sortRisks).map((risk, index) => (
                                    <Row risk={risk} index={index} key={risk.id} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {risks.length === 0 && (
                    <div className={styles.noDataAvailable}>
                        <p style={{marginTop: "30px"}}>{t("no-data-available")}</p>
                    </div>
                )}
            </div>
        )
    }

    return (
        <Box sx={{width: "100%", marginTop: "10px"}}>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs value={value} onChange={handleChange} aria-label="risks tabs">
                    <Tab label={t("open-risks")} sx={{fontSize: "0.75rem"}} />
                    <Tab label={t("closed-risks")} sx={{fontSize: "0.75rem"}} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <RisksTable risks={openRisks} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <RisksTable risks={closedRisks} />
            </TabPanel>
        </Box>
    )
}

function sortRisks(left: StatusReportRisk, right: StatusReportRisk): number {
    let result = 0
    if (left.next_assessment && right.next_assessment) result = left.next_assessment.getTime() - right.next_assessment.getTime()
    if (result === 0 && left.next_assessment) result = -1
    if (result === 0 && right.next_assessment) result = 1
    if (result === 0) result = left.description.localeCompare(right.description)
    return result
}

interface RisksProps {
    report: StatusReportData
    queryRefetch: any
}

export const Risks: React.FC<RisksProps> = ({report, queryRefetch}) => {
    const auth = useAuth()
    const isReportEditable = report.isEditableBy(auth.current())

    const [update_risks_comment] = useMutation<UpdateRisksComment, UpdateRisksCommentVariables>(updateRisksComment)
    const doUpdateComment = async (comment: string | null) => {
        await update_risks_comment({
            variables: {
                report_id: report.id,
                comment: comment,
            },
        })
        queryRefetch()
    }

    const updateComment = async (value: string | null) => await doUpdateComment(value)
    const deleteComment = async () => await doUpdateComment(null)

    return (
        <>
            <CommentTextCell
                initialValue={report.risks.comment}
                deleteComment={deleteComment}
                onChange={updateComment}
                editable={isReportEditable}
            />
            <RisksSection risks={report.risks.list} />
        </>
    )
}
